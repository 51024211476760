html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#frameLoader{
  display: none;
}

*{
  &:focus{
    outline: none;
  }
}
body {
  overflow: hidden;
  background-color: #0d1221;
  .ui-front {
    position: relative;
  }


  .outer-wrap{
    position: relative;
    height: 100%;
    /* min-width: 1000px; */
    overflow: hidden;
    .global-loader {
      position: absolute;
      top:0;
      left:0;
      right:0;
      z-index: 101;
      height: 2px;
      display: block;

      .left, .right {
        position: absolute;
        background: $green;
        left:0;
        right:0;
        top:0;
        bottom:0;
      }

      .left {
        right: 50%;
        left: 50%;
      }
      .right {
        right: 50%;
        left: 50%;
      }
    }
    &.new-feed__channel {
      .feed-block {
        .tab-panel {
          .nav-tabs {
            &.nav-justified {
              @media (max-width: 768px) {
                background-color: #233055;
              }
              li {
                &.stream {
                  @media (max-width: 1190px) {
                    display: none;
                  }
    
                  @media (max-width: 768px) {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }

      header {
        @media (max-width: 768px) {
          background-color: #233055;
        }
      }
    }

    &.loading .global-loader {
      .left {
        transition: left 2s ease;
        left: 3%;
      }
      .right {
        transition: right 2s ease;
        right: 3%;
      }
    }

    &.loaded .global-loader {
      .left {
        transition: right .5s ease-in .3s, left .3s ease;
        left: 0;
        right: 100%;
      }

      .right {
        transition: left .5s ease-in .3s, right .3s ease;
        left: 100%;
        right: 0;
      }
    }

  }
  &.noanim {
    header, .feed-block, .content-container, .main-inner-wrap {
      transition: none !important;
    }
  }
  &.no-ads{
    .main-block .main-inner-wrap{
      margin-top: 0;
    }
    .banner-block{
      display: none;
    }
    .main-inner-wrap {
      max-width: inherit;
    }
    .wrapper {
      background-image: none;
    }
  }
  &.hidden {
    header {
      width: calc(100% - 240px);
      transition: width 0.5s ease;
      z-index: 8;
      &.wide{
        width: 100%;
        z-index: 8;
      }
      @media(max-width: 1190px){
        width: calc(100% - 240px);
      }
    }
    .wrapper{
      background-position: 50% top !important;

      @media (min-width: 2000px) {
        margin: 0 auto;
        max-width: 2000px;
      }
    }
    .banner-block{
      &.head{
        .aside-block{
          display: block;
        }
      }
    }
    .main-block {
      margin: 0;
      .promo-block,.main-inner-wrap{
        width: auto;
      }
      .promo-block{
        transform: translate(175px, 0);
      }
      .main-inner-wrap{
        margin: 0 auto;
        z-index: 3;
      }
    }
    &.no-ads{
      .main-block{
        .main-inner-wrap{
          margin: 0 auto;
          z-index: 3;
        }
      }
    }
    .feed-block {
      width: 50px;
      height: 48px;
      overflow: hidden;
      z-index: 7;
      &.wide {
        width: 240px;
      }
      .stream-block{
        .chat{
          overflow: hidden;
        }
      }
      @media (max-width: 1190px){
        width: 210px;
      }
    }
    @media (max-width: 1190px) {
      .main-block {
        margin: 0;
        .promo-block{
          margin: 0;
        }
      }
    }
  }
  &.scrollable {
    overflow: auto;
  }
  @media only screen and (min-resolution: 2dppx) {
    /* font-weight: 200; */
  }
}

@media (max-width: 360px) {
  .old-site {
    overflow-x: scroll;
  }
}

.wrapper {
  position: relative;
  margin: 0 auto;
  max-width: 2000px;
  height: 100%;
  background: none center top no-repeat;
  background-position: calc(50% - 175px) top !important;
  transition: background-position 0.5s ease;

  @media(min-width: 2000px) {
    background-position: 50% top !important;
    max-width: none;
    margin: 0;
    width: calc(100% - 350px);
  }
}

.wrapper__max-width {
  max-width: none;
  width: 100%;

  @media (min-width: 2000px) {
    background-position: calc(50% - 175px) top !important;
  }

  .channel-wrapper {
    max-width: none;
  }
}


// full hd
header {
  position: relative;
  width: calc(100% - 350px);
  height: 48px;
  transition: width 0.5s ease;
  background-color: $mainColor;
  box-shadow: -3px 1px 2px rgba(0,0,0, 0.8);
  z-index: 7;
  
  .wrap {
    position: relative;
    margin: 0 auto;
  }
  &.wide{
    width: 100%;
  }
  @media(max-width: 1190px){
    width: calc(100% - 210px);
  }
}

.main-block {
  position: relative;
  width: 100%;
  height: calc(100% - 48px);
  .promo-block{
    position: relative;
    width: calc(100% - 350px);
    height: 200px;
    transition: margin 0.5s ease;
  }
  .main-inner-wrap {
    position: relative;
    padding: .993% .993% 0;
    width: calc(100% - 350px);
    max-width: 1570px;
    left: 0;
    transform: translate(0, 0);
    background-color: $darkestBlue;

    @media(min-width: 2000px) {
      margin: 0 auto;
    }

    &.new-color__channel {
      background-color: #0C0F12;
      max-width: none;
      margin: 0;

      @media (min-width: 2000px) {
        width: 100%;
      }
    }
  }
  .tse-scrollable {
    height: 100%;
    &.scroller {
      padding: 0;
      margin: 0;
    }
  }
  .tse-scroll-content {
    will-change: scroll-position;
  }

  .adaptive-flex__wrapper {
    display: flex;

    .adaptive-wrapper {
      width: auto;
      margin: 0 1.2% 2% 90px;

      @media (max-width: 1850px) {
        margin: 0 1.2% 2% 0;
      }
    }
  }

  .left-block-wrap {
    width: auto;

    .news-block {
      width: 700px;
      max-width: 700px;

      @media (max-width: 1300px) {
        width: 600px;
      }

      @media (max-width: 1190px) {
        width: 700px;
      }
    }
  }
  .banner-news-wrap {
    width: 60.55%;
    float: left;
    margin: 0;
    padding: 0 1.6% 1.181% 0;

    iframe, img {
      display: block;
      margin: 0 auto;
    }
  }
  .big-block-wrap {
    position: relative;
    width: 100%;

    .tournaments-wrap{
      margin: 0 0 1.181% 0;
      width: 100%;
      .tournaments-block{
        margin-bottom: 3.05%;
        border-radius: 4px;
        background-color: $dark;
      }
    }

  }
  .small-block-wrap {
    width: 100%;

    .forum-wrap, .broadcasts-wrap{
      position: relative;
      width: 50%;
      margin: 0 0 3.354% 0;
      .forum-block, .broadcasts-block {
        position: relative;
        border-radius: 4px;
        background-color: $dark;
      }
      .forum-block {
        margin-right: 3.05%;
      }
      .broadcasts-block {
        margin-left: 3.05%;
      }
    }
    .video-wrap{
      position: relative;
      margin: 0 0 3.354% 0;
      .video-block {
        border-radius: 4px;
        background-color: $dark;
      }
    }
    .gallery-wrap{
      position: relative;
      margin: 0 0 3.354% 0;
      border-radius: 4px;
      background-color: $dark;
    }
  }

  .order-block {
    display: flex;
    justify-content: center;

    @media (max-width: 1750px) {
      flex-direction: column;
    }

    .adaptive-block {
      display: none;

      @media (max-width: 1750px) {
        display: flex;
      }
    }

    .flex-wrapper {
      display: flex;
      flex-direction: column;
      margin-right: 75px;
      width: 635px;
      max-width: 635px;
      margin-top: 13px;

      @media (max-width: 1850px) {
        margin-right: 20px;
      }

      @media (max-width: 1750px) {
        margin-right: 0;
        width: auto;
      }

      @media (max-width: 1400px) {
        margin-top: 0;
      }

      .big-block-wrap {
        @media (max-width: 1750px) {
          display: none;
        }
      }
    }

    .adaptive-wrapper {
      @media (max-width: 950px) {
        display: flex;
      }
    }
  }
}

.hidden{
  .main-inner-wrap{
    .adaptive-flex__wrapper {
      .adaptive-wrapper {
        margin: 0 1.2% 2% 90px;

        @media (max-width: 1400px) {
          margin: 0 1.2% 2% 0;
        }
      }
    }

    .left-block-wrap{
      .news-block {

        @media (max-width: 1300px) {
          width: 700px;
        }

        @media (max-width: 950px) {
          width: auto;
          max-width: none;
        }
      }
    }

    .flex-wrapper {
      margin-right: 75px;

      @media (max-width: 1400px) {
        margin-right: 0;
      }

      .big-block-wrap {
        display: block;

        @media (max-width: 1400px) {
          display: none;
        }
      }
    }

    .adaptive-block {
      display: none;

      @media (max-width: 1400px) {
        display: flex;
      }
    }

    .order-block {
      @media (max-width: 1400px) {
        flex-direction: column;
      }
    }
  }
}

.feed-block {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 350px;
  will-change: transform;
  transform: translate(0, 0);
  transition: transform 0.5s ease, width 0.5s ease;
  .resizer{
    position: absolute;
    display: none;
    top: 0;
    left: -8px;
    bottom: 0;
    width: 10px;
    cursor: move;
    border-right: 2px solid $mainColor;
    transition: .2s border;
    z-index: 2;
    &:hover{
      border-color: $accent;
    }
  }
  .ghost-block{
    will-change: transform;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-width: 1px;
    background: rgba(82, 112, 156,.25);
    pointer-events: none;
    opacity: 0;
    transition: .2s opacity,.2s background;
    z-index: 2;
  }
  .opener{
    display: none;
    position: absolute;
    top: 50%;
    margin-top: -18px;
    left: 0;
    width: 35px;
    height: 35px;
    font-family: "icomoon";
    font-size: 18px;
    color: $white;
    text-align: center;
    line-height: 35px;
    background-color: $mainColor;
    border-radius: 2px;
    transform: translate(0, 0);
    transition: .3s all;
    cursor: pointer;
    z-index: 2;
    &:hover{
      background-color: $accent;
    }
    &:before{
      content: "\e901";
    }
  }
  .head-container{
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    transform: translate(0, 0);
    transition: transform 0.5s ease;
  }
  .content-container{
    position: absolute;
    top: 48px;
    right: 0;
    width: 100%;
    height: calc( 100% - 48px);
    background-color: $mainBackgr;
  }
  @media (max-width: 1190px){
    width: 210px;
    .content-container{
      width: 350px;
    }
  }
  &.resizing{
    user-select: none;
    .ghost-block{
      opacity: 1;
    }
    &.close{
      .ghost-block{
        background-color: rgba(228,45,45,.25);
      }
    }
  }
  &.closed{
    .chat-container{
      padding: 0;
    }
  }
}
.feed-block-switcher {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  border-right: 3px solid transparent;
  height: 100%;
  cursor: pointer;
  text-align: center;
  transition: transform 0.5s ease;
  z-index: 8;
  &:hover {
    border-color: $accent;
    .icon-arrow-left{
      display: block;
    }
    .icon{
      background-color: $accent;
    }
  }
  .icon {
    position: absolute;
    top: 50%;
    right: -14px;
    vertical-align: top;
    color: #fff;
    border-radius: 50%;
    background-color: rgba(52, 71, 127, 0.5);
    padding: 8px 10px 8px 10px;
    display: none;
    &.icon-arrow-left {
      padding: 8px 10px 8px 11px;
    }
  }
}

.likely{
  &.likely_gg{
    .likely__icon{
      display: none;
    }
    .likely__widget{
      display: inline-block;

      width: 24px;
      height: 24px;

      vertical-align: top;
      padding: 0 2px;

      background-color: $white;
      border-radius: 2px;
      cursor: pointer;

      &:not(:last-child) {
        margin: 0 12px 0 0;
      }
      .likely__button{
        color: $white;
      }
      @media(max-width: 768px) {
        &_vkontakte{
          padding-top: 2px;
        }
      }
      &_vkontakte{
        background-color: #4680C2;
        &:hover{
          background-color: #77A3D5;
        }
      }
      &_twitter{
        background-color: #5d9ec9;
        &:hover{
          background-color: #69adda;
        }
      }
      &_odnoklassniki{
        background-color: #eb722e;
        display: none;
        &:hover{
          background-color: #f4874a;
        }
      }
      &_facebook{
        background-color: #1877F2;
        &:hover{
          background-color: #4D8BDC;
        }
      }
      .icon{
        font-size: 16px;
        vertical-align: middle;
        margin-left: 2px;
        &.icon-twitter{
          display: inline-block;
          vertical-align: bottom;
          margin: 2px 0 0;
          margin-left: 3px;
          font-size: 13px;
        }
        &.icon-odnoklassniki{
          display: inline-block;
          vertical-align: top;
          margin: 2px 1px 0 4px;
          padding-bottom: 4px;
          font-size: 22px;
        }
        &.icon-facebook{
          display: inline-block;
          vertical-align: bottom;
          margin: 1px -2px -5px 0px;
          font-size: 20px;
        }
      }
      @media(max-width: 768px) {
        .icon {
          vertical-align: sub;
        }
      }
      .text{
        display: inline-block;
        vertical-align: top;
        margin: 3px 0 0 6px;
      }
      .likely__counter{
        display: none;
        vertical-align: top;
        margin: 3px 3px 0 0;
        font-weight: 600;
      }
    }
  }
}

// hd
@media (min-width: 1870px){
  .main-inner-wrap{
    .tournaments-block{
      .tournaments-list {
        justify-content: space-between;
        .tournament {
          width: 45%;
        }
      }
    }
  }
}
// 1870
@media (min-width: 1190px) and (max-width: 1750px) {
  .main-inner-wrap{
    padding: 1.045% 1.045% 0;
    .banner-news-wrap {
      width: 100%;
      float: none;
      margin: 0 0 1.181%;
      padding: 0;
    }
    .big-block-wrap {
      .tournaments-wrap{
        .tournaments-block{
          margin-bottom: 1.174%;
        }
      }
    }
    .tournaments-block{
      .tournaments-list{
        justify-content: space-between;
        .tournament{
          width: calc(32% - 24px);
          margin: 0 20px 24px 0;
          vertical-align: top;
          display: inline-block;
          text-align: left;
          zoom: 1;
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .main-inner-wrap{
    .small-block-wrap {
      .forum-wrap, .broadcasts-wrap {
        width: 100%;
      }
      .broadcasts-wrap{
        .broadcasts-block{
          margin-left: 0;
        }
      }
      .forum-wrap{
        .forum-block{
          margin-right: 0;
        }
      }
    }
  }
}

@media (min-width: 1400px) and (max-width: 1870px) {
  .hidden{
    .main-inner-wrap{
      .banner-news-wrap {
        width: 100%;
        float: none;
        margin: 0 0 1.181%;
        padding: 0;
      }
      .big-block-wrap{
        .tournaments-wrap{
          .tournaments-block{
            margin-bottom: 3.05%;
          }
        }
      }
      .small-block-wrap{
        .forum-wrap, .broadcasts-wrap {
          width: 50%;
        }
        .broadcasts-wrap{
          .broadcasts-block{
            margin-left: 3.05%;
          }
        }
        .forum-wrap{
          .forum-block{
            margin-right: 3.05%;
          }
        }
      }
      .tournaments-block{
        .tournaments-list{
          justify-content: space-between;
          .tournament{
            width: 45%;
          }
        }
      }
      .video-wrap{
        .video-block{
          .video-list{
            .video {
              position: relative;
              width: 48%;
              margin: 0 0 4% 0;
              @include aspect-ratio(16, 9, 'link');
              img {
                width: 100%;
                height: 100%;
              }
              .img-block{
                display: block;
                width: 100%;
                height: 100%;
                .gradient-block{
                  display: none;
                }
              }
              .link {
                .info {
                  position: absolute;
                  width: 100%;
                  height: 50%;
                  left: 0;
                  bottom: 0;
                  font-size: 14px;
                  line-height: 21px;
                  background-image: (linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000 100%));
                  .user, .text {
                    position: absolute;
                    display: block;
                    left: 10px;
                    right: 10px;
                    bottom: 8px;
                    color: #fff;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    margin-bottom: 0;
                  }
                  .text{
                    font-size: 14px;
                    line-height: 21px;
                  }
                  .user {
                    color: $linkBlue;
                    bottom: 30px;
                  }
                  .view-count{
                    display: none;
                    color: #585f73;
                  }
                }
                &:hover {
                  .img-block{
                    color: $white;
                    @include img-hover;
                    .icon-video-icon {
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1190px) {
  .main-block {
    .main-inner-wrap{
      width: 100%;
      transform: translate(0,0);

      // &.new-color__channel {
      //   width: calc(100% - 350px);
      // }
      .tournaments-block {
        .tournaments-list {
          justify-content: space-between;
          .tournament {
            width: calc(32% - 24px);
          }
        }
      }
    }
    .stream-selector {
      margin: 0 0 0.9% 0;
    }

    .big-block-wrap {
      .tournaments-wrap{
        width: 100%;
        .tournaments-block{
          margin-bottom: 0.9%;
        }
      }
    }
    .small-block-wrap {
      .forum-wrap, .broadcasts-wrap {
        width: 100%;
        .forum-block, .broadcasts-block {
          margin: 0 0 3% 0;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .main-block {
    .main-inner-wrap{
      &.new-color__channel {
        width: 100%;
        height: 100%;

        #entire-wrapper {
          height: 100%;

          #entire {
            height: 100%;
          
            & > div {
              height: 100%;
          
              .channel-wrap {
                .chat-height__wrapper {
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                }
              }
            }
          }
        }
      }
    }
  }
}
