@charset "UTF-8";
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], template {
  display: none; }

html {
  background: #fff;
  color: #000;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

html,
button,
input,
select,
textarea {
  font-family: sans-serif; }

body {
  margin: 0; }

a {
  background: transparent; }
  a:focus {
    outline: thin dotted; }
  a:hover, a:active {
    outline: 0; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

h2 {
  font-size: 1.5em;
  margin: 0.83em 0; }

h3 {
  font-size: 1.17em;
  margin: 1em 0; }

h4 {
  font-size: 1em;
  margin: 1.33em 0; }

h5 {
  font-size: 0.83em;
  margin: 1.67em 0; }

h6 {
  font-size: 0.75em;
  margin: 2.33em 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #000; }

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em; }

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: "\201C" "\201D" "\2018" "\2019"; }

q:before, q:after {
  content: '';
  content: none; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0;
  white-space: normal; }

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  vertical-align: baseline; }

button, input {
  line-height: normal; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
input[disabled] {
  cursor: default; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }
  input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  font-size: 14px; }

.ng-cloak {
  display: none; }

* {
  box-sizing: border-box; }

h1, h2, h3, h4, h5 {
  color: #fff; }

h1, .h1 {
  font-size: 34px;
  font-weight: 300;
  line-height: 46px; }

h2, .h2 {
  font-size: 22px;
  font-weight: 400;
  line-height: 30px; }

h3, .h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px; }

ul {
  padding-left: 18px; }

ol {
  padding-left: 20px; }

p {
  color: #fff; }
  p.error {
    color: #fe4b4b; }

a {
  color: #73adff;
  text-decoration: none; }
  a:hover {
    color: #4f98ff; }

.mobile {
  display: none; }

.semibold {
  font-weight: 600; }

.rub-sign {
  position: relative;
  display: inline-block;
  line-height: 1;
  vertical-align: baseline; }
  .rub-sign:before {
    content: '\2212';
    font-size: 0.9em;
    position: absolute;
    top: 14%; }
  .rub-sign:after {
    content: '\002D';
    position: absolute;
    top: 11%;
    left: 0; }
  .rub-sign.semibold {
    font-weight: 600; }
  .rub-sign.bold {
    font-weight: 700; }

.pull-left {
  float: left !important; }

.pull-right {
  float: right !important; }

.ov {
  overflow: hidden; }

.clearfix:before, .clearfix:after {
  display: table;
  content: "";
  line-height: 0; }

.clearfix:after {
  clear: both; }

.form-group {
  position: relative;
  margin-bottom: 15px; }
  .form-group label {
    display: inline-block;
    margin-bottom: 2px; }

.dropbox {
  position: relative;
  background-color: #0d1221;
  border: 2px dashed #2b3f63; }
  .dropbox img {
    width: 100%;
    height: 100%; }
  .dropbox.ready {
    border: 2px dashed #73adff; }
  .dropbox .base {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    background-color: rgba(13, 18, 33, 0.7);
    transition: transform 0.3s ease-in-out; }
    .dropbox .base .cloud {
      position: relative;
      margin: 21% auto 0;
      width: 65px;
      height: 40px;
      background: url("../../../images/svg/cloud.svg") no-repeat; }
    .dropbox .base .tip {
      color: #73adff;
      font-weight: 600;
      font-size: 24px;
      line-height: 33px; }
    .dropbox .base .ext {
      font-size: 14px;
      line-height: 19px;
      color: #405f90; }
    .dropbox .base.hide {
      display: none; }
  .dropbox .edit-block {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 19px; }
    .dropbox .edit-block .icon {
      cursor: pointer;
      display: inline-block;
      vertical-align: top;
      margin-top: -50px;
      opacity: 0;
      color: #fff;
      transition: opacity 0.5s ease, margin-top 0.35s 0.05s cubic-bezier(0.17, 0.55, 0.71, 1.65); }
  .dropbox .load-screen {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .dropbox.loading {
    border: 2px solid #2b3f63; }
    .dropbox.loading .base {
      transform: translate(-50%, -50%) scale(0); }
    .dropbox.loading .load-screen {
      display: block; }
  .dropbox.loaded .edit-block .icon {
    margin-top: 0;
    opacity: 0.99; }
  .dropbox.loaded .base {
    display: none; }
  .dropbox.hover {
    border: 2px dashed #73adff; }
    .dropbox.hover .base {
      display: block; }

.system-message {
  display: inline-block;
  vertical-align: top;
  padding: 7px 14px 7px 12px;
  margin-bottom: 10px;
  color: #fff;
  font-size: 14px;
  line-height: 1.5em;
  cursor: default;
  border-radius: 2px; }
  .system-message:empty {
    padding: 0;
    margin: 0; }
  .system-message.error {
    border-left: 4px solid #fe4b4b;
    background-color: #8f3d50; }
    .system-message.error.smooth-error {
      animation: fadeIn 3s;
      animation-fill-mode: forwards; }
  .system-message.system {
    border-left: 4px solid #53709B;
    background-color: rgba(68, 83, 126, 0.5); }
  .system-message.system-message_yellow {
    margin-top: 16px;
    background: rgba(231, 188, 41, 0.24);
    border-left: 4px solid #F2F932; }
  .system-message.success {
    border-left: 4px solid #68c67b;
    background-color: #41765f; }
  .system-message.warning {
    border-left: 4px solid #e67919;
    background-color: #b1611b; }
  .system-message.smooth {
    animation: fadeIn 3s;
    animation-fill-mode: forwards; }

.btn.disabled, .btn[disabled], fieldset[disabled] {
  pointer-events: none;
  cursor: not-allowed;
  box-shadow: none; }

.btn {
  position: relative;
  display: inline-block;
  width: auto;
  vertical-align: top;
  color: #fff;
  font-size: 14px;
  line-height: 19px;
  border: none;
  padding: 8px 16px;
  outline: none !important;
  cursor: pointer;
  border-radius: 2px;
  user-select: none;
  text-decoration: none; }
  .btn:hover {
    color: #fff; }
  .btn.btn-blue {
    background-color: #3a6097; }
    .btn.btn-blue:hover {
      background-color: #4d73ac; }
    .btn.btn-blue:active {
      background-color: #3864a4; }
    .btn.btn-blue.light {
      background-color: #5d94e2; }
      .btn.btn-blue.light:hover {
        background-color: #7fa5dd; }
      .btn.btn-blue.light:active {
        background-color: #4c8dea; }
  .btn.btn-transparent {
    color: #73adff;
    background-color: #2b3553; }
    .btn.btn-transparent:hover {
      color: #4f98ff;
      background-color: #353f5d; }
    .btn.btn-transparent:active {
      color: #fff;
      background-color: #3f4967; }
    .btn.btn-transparent.two {
      background-color: #303d61; }
      .btn.btn-transparent.two:hover {
        background-color: #384467; }
    .btn.btn-transparent.dark {
      background-color: #181b24; }
      .btn.btn-transparent.dark:hover {
        background-color: #272c3b; }
      .btn.btn-transparent.dark:active {
        background-color: #2f3547; }
  .btn.btn-red {
    background-color: #b83939; }
    .btn.btn-red:hover {
      background-color: #d15d5d; }
    .btn.btn-red:active {
      background-color: #c73737; }
    .btn.btn-red.light {
      background-color: #fe4b4b; }
      .btn.btn-red.light:hover {
        background-color: #fe6e6e; }
      .btn.btn-red.light:active {
        background-color: #ff3c3c; }
  .btn.btn-gray {
    background-color: #607695; }
    .btn.btn-gray:hover {
      background-color: #7b8da5; }
    .btn.btn-gray:active {
      background-color: #5b79a4; }
    .btn.btn-gray.light {
      background-color: #8a9db7; }
      .btn.btn-gray.light:hover {
        background-color: #93a9c4; }
      .btn.btn-gray.light:active {
        background-color: #8a9db7; }
  .btn.btn-green {
    background-color: #3a9749; }
    .btn.btn-green:hover {
      background-color: #52ab60; }
    .btn.btn-green:active {
      background-color: #35a847; }
    .btn.btn-green.light {
      background-color: #3faf56; }
      .btn.btn-green.light:hover {
        background-color: #5cb16d; }
      .btn.btn-green.light:active {
        background-color: #3ac156; }
  .btn.btn-twitter {
    color: #fff;
    background-color: #5d9ec9; }
    .btn.btn-twitter:before {
      content: '\e020';
      font-family: 'icomoon';
      font-size: 25px;
      display: inline-block;
      vertical-align: top;
      margin-right: 13px; }
  .btn.btn-vk {
    color: #fff;
    background-color: #4b6c91; }
    .btn.btn-vk:hover {
      background-color: #5e83ad; }
    .btn.btn-vk:before {
      content: '\e018';
      font-family: 'icomoon';
      font-size: 25px;
      display: inline-block;
      vertical-align: top;
      margin-right: 13px; }
  .btn.btn-fb {
    color: #fff;
    background-color: #3a599a;
    padding: 7.5px 16px; }
    .btn.btn-fb:before {
      content: '\e021';
      font-family: 'icomoon';
      font-size: 30px;
      display: inline-block;
      vertical-align: top;
      margin: 4px 3px -3px -8px; }
  .btn.btn-ok {
    color: #fff;
    border: 1px solid #eb722e;
    background-color: #eb722e; }
    .btn.btn-ok:before {
      content: '\e925';
      font-family: 'icomoon';
      font-size: 21px;
      display: inline-block;
      vertical-align: top;
      margin-right: 13px; }
  .btn.btn-pending {
    border: solid 2px #3a9749;
    padding: 6px 16px; }
  .btn.btn-plus {
    background-color: #a74ac6; }
    .btn.btn-plus:hover {
      background-color: #E04BFF; }
    .btn.btn-plus:active {
      background-color: #BF4CDE; }
  .btn.disabled {
    cursor: default;
    pointer-events: none; }
  .btn.btn-blue.disabled, .btn.btn-gray.disabled, .btn.btn-green.disabled, .btn.btn-red.disabled {
    background-color: #656871;
    color: #b2b3b8; }
    .btn.btn-blue.disabled.light, .btn.btn-gray.disabled.light, .btn.btn-green.disabled.light, .btn.btn-red.disabled.light {
      background-color: #8f929b;
      color: #c7c8cd; }
  .btn.loading {
    color: transparent;
    width: inherit;
    height: inherit;
    min-width: 54px;
    background-image: url(/images/chat/ajax_loading.svg);
    background-position: center;
    background-repeat: no-repeat; }
  .btn.wide {
    display: block;
    text-align: center; }
  .btn.btn-flex {
    display: flex;
    justify-content: center;
    align-items: center; }
    .btn.btn-flex .icon {
      font-size: 12px;
      margin-right: 8px; }

input[type="text"], textarea, input[type="tel"], input[type="password"], input[type="email"], input[type="number"], .textarea, input[type="url"] {
  display: block;
  color: #fff;
  width: 100%;
  font-size: 14px;
  line-height: 19px;
  padding: 7px 11px;
  border: 1px solid transparent;
  background-color: #0d1221;
  border-radius: 2px; }
  input[type="text"]::placeholder, textarea::placeholder, input[type="tel"]::placeholder, input[type="password"]::placeholder, input[type="email"]::placeholder, input[type="number"]::placeholder, .textarea::placeholder, input[type="url"]::placeholder {
    color: #565a64; }
  input[type="text"]:focus, input[type="text"].focused, textarea:focus, textarea.focused, input[type="tel"]:focus, input[type="tel"].focused, input[type="password"]:focus, input[type="password"].focused, input[type="email"]:focus, input[type="email"].focused, input[type="number"]:focus, input[type="number"].focused, .textarea:focus, .textarea.focused, input[type="url"]:focus, input[type="url"].focused {
    border: 1px solid #52709c;
    outline: none;
    box-shadow: inset 0 0 6px 0 rgba(82, 112, 156, 0.3); }
  input[type="text"]:invalid, input[type="text"].error, input[type="text"].ng-invalid.ng-touched, textarea:invalid, textarea.error, textarea.ng-invalid.ng-touched, input[type="tel"]:invalid, input[type="tel"].error, input[type="tel"].ng-invalid.ng-touched, input[type="password"]:invalid, input[type="password"].error, input[type="password"].ng-invalid.ng-touched, input[type="email"]:invalid, input[type="email"].error, input[type="email"].ng-invalid.ng-touched, input[type="number"]:invalid, input[type="number"].error, input[type="number"].ng-invalid.ng-touched, .textarea:invalid, .textarea.error, .textarea.ng-invalid.ng-touched, input[type="url"]:invalid, input[type="url"].error, input[type="url"].ng-invalid.ng-touched {
    border: 1px solid #fe4b4b;
    box-shadow: inset 0 0 6px 0 rgba(254, 110, 110, 0.3); }
  input[type="text"].disabled, input[type="text"]:disabled, textarea.disabled, textarea:disabled, input[type="tel"].disabled, input[type="tel"]:disabled, input[type="password"].disabled, input[type="password"]:disabled, input[type="email"].disabled, input[type="email"]:disabled, input[type="number"].disabled, input[type="number"]:disabled, .textarea.disabled, .textarea:disabled, input[type="url"].disabled, input[type="url"]:disabled {
    cursor: default;
    pointer-events: none;
    color: #868890;
    border-color: #868890;
    background-color: transparent;
    box-shadow: inset 0 0 0 1px #868890; }

.checkbox, .radio {
  position: relative;
  font-size: 14px;
  line-height: 19px;
  color: #fff; }
  .checkbox label, .radio label {
    padding-left: 25px;
    user-select: none;
    cursor: pointer; }
  .checkbox.disabled, .radio.disabled {
    opacity: 0.5; }
    .checkbox.disabled:before, .radio.disabled:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1; }

.checkbox label {
  min-width: 20px;
  line-height: 19px; }

input[type="checkbox"],
input[type="radio"] {
  display: none; }

bdo input[type="checkbox"] {
  display: inline; }

input[type="checkbox"] + label::before,
input[type="radio"] + label::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 0;
  display: inline-block;
  height: 15px;
  width: 15px;
  margin: 0 5px 0 0;
  background: url(/images/rc-sprite2.png) no-repeat; }

input[type="checkbox"] + label::before {
  background-position: -5px -5px; }

input[type="checkbox"] + label:hover::before {
  background-position: -5px -30px; }

input[type="checkbox"]:checked + label::before {
  background-position: -5px -55px; }

input[type="radio"] + label::before {
  background-position: -30px -5px; }

input[type="radio"] + label:hover::before {
  background-position: -30px -30px; }

input[type="radio"]:checked + label::before {
  background-position: -30px -55px; }

.radio.active input[type="radio"] + label::before {
  background-position: -30px -55px; }

.dropdown {
  position: relative;
  color: #fff;
  font-size: 14px;
  user-select: none; }
  .dropdown select, .dropdown .dropdown-btn {
    min-width: 200px;
    padding: 6px 11px;
    cursor: pointer;
    border: 1px solid transparent;
    background-color: #0d1221;
    height: 35px;
    border-radius: 2px; }
  .dropdown.disabled {
    opacity: 0.7; }
  .dropdown .hidden {
    display: none; }
  .dropdown .dropdown-btn:hover {
    border: 1px solid #52709c;
    box-shadow: inset 0 0 6px 0 rgba(81, 112, 156, 0.3); }
  .dropdown .dropdown-btn .text {
    margin-right: 20px;
    line-height: 21px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .dropdown .dropdown-btn .caret {
    position: absolute;
    top: 16px;
    right: 12px;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent; }
  .dropdown .dropdown-btn.disabled {
    color: #565a64;
    cursor: default; }
    .dropdown .dropdown-btn.disabled:hover {
      border: 1px solid transparent;
      box-shadow: none; }
  .dropdown .dropdown-menu {
    position: absolute;
    top: 0;
    display: none;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    min-width: 160px;
    width: 100%;
    background-color: #4f5977;
    z-index: 10;
    border-radius: 2px;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.3); }
    .dropdown .dropdown-menu.divider {
      height: 1px;
      margin: 9px 0;
      overflow: hidden;
      background-color: #e5e5e5; }
    .dropdown .dropdown-menu > li > a, .dropdown .dropdown-menu .simplebar-content > li > a {
      display: block;
      padding: 7px 12px;
      line-height: 21px;
      font-weight: normal;
      color: #fff;
      text-decoration: none;
      white-space: nowrap; }
      .dropdown .dropdown-menu > li > a:hover, .dropdown .dropdown-menu > li > a:focus, .dropdown .dropdown-menu .simplebar-content > li > a:hover, .dropdown .dropdown-menu .simplebar-content > li > a:focus {
        text-decoration: none;
        background-color: #52709c; }
    .dropdown .dropdown-menu > li hr, .dropdown .dropdown-menu .simplebar-content > li hr {
      height: 1px; }
    .dropdown .dropdown-menu.active > a, .dropdown .dropdown-menu.active a:hover, .dropdown .dropdown-menu.active a:focus {
      color: #fff;
      text-decoration: none;
      outline: 0;
      background-color: #337ab7; }
    .dropdown .dropdown-menu .disabled > a {
      color: #777777; }
      .dropdown .dropdown-menu .disabled > a:hover, .dropdown .dropdown-menu .disabled > a:focus {
        color: #777777;
        text-decoration: none;
        background-color: transparent;
        background-image: none;
        cursor: not-allowed; }
  .dropdown.open {
    z-index: 100; }
    .dropdown.open .dropdown-menu {
      display: block; }
    .dropdown.open > a {
      outline: 0; }
  .dropdown .dropdown-header {
    display: block;
    padding: 3px 20px;
    font-size: 12px;
    line-height: 1.42857143;
    color: #777777;
    white-space: nowrap; }
  .dropdown.blue .dropdown-btn {
    background-color: #3a6097; }
    .dropdown.blue .dropdown-btn:hover {
      background-color: #4d73ac;
      border: 1px solid #6f93ca; }
    .dropdown.blue .dropdown-btn.disabled {
      color: #ccd9eb;
      background-color: #5a7398; }
      .dropdown.blue .dropdown-btn.disabled > a:hover, .dropdown.blue .dropdown-btn.disabled > a:focus {
        text-decoration: none;
        background-color: transparent;
        cursor: not-allowed; }
      .dropdown.blue .dropdown-btn.disabled:hover {
        background-color: #5a7398;
        border: 1px solid transparent; }
  .dropdown.blue .dropdown-menu {
    background-color: #3a6097; }
    .dropdown.blue .dropdown-menu > li > a:hover {
      background-color: #617fab; }

.dropdown-toggle:focus {
  outline: 0; }

:not(table.gg-spoiler) .spoiler-block .spoiler-head {
  user-select: none; }

gg-custom-spoiler .spoiler-block {
  display: block; }

gg-custom-spoiler > * {
  display: none; }

.spoiler-block {
  position: relative; }
  .spoiler-block .spoiler-head {
    position: relative;
    padding: 8px 85px 8px 16px;
    font-size: 14px;
    line-height: normal;
    min-height: 35px;
    color: #dfecff;
    background-color: rgba(79, 89, 119, 0.3);
    cursor: pointer;
    border-radius: 2px; }
    .spoiler-block .spoiler-head:after {
      content: attr(data-show);
      position: absolute;
      top: 8px;
      right: 16px;
      color: #4f98ff; }
    .spoiler-block .spoiler-head:hover {
      background-color: rgba(79, 89, 119, 0.5); }
      .spoiler-block .spoiler-head:hover:after {
        color: #73adff; }
      .spoiler-block .spoiler-head:hover .show-link {
        color: #4f98ff; }
    .spoiler-block .spoiler-head .show-link {
      position: absolute;
      top: 8px;
      right: 16px; }
      .spoiler-block .spoiler-head .show-link .show {
        display: block; }
      .spoiler-block .spoiler-head .show-link .hidden {
        display: none; }
  .spoiler-block .spoiler-content {
    border: 2px solid transparent;
    border-top: none;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease-in-out; }
    .spoiler-block .spoiler-content .inner-block {
      padding: 30px; }
  .spoiler-block.active .spoiler-head {
    border-radius: 2px 2px 0 0; }
    .spoiler-block.active .spoiler-head:after {
      content: attr(data-hide); }
  .spoiler-block.active .spoiler-content {
    padding: 18px 24px;
    height: auto;
    border-color: rgba(79, 89, 119, 0.3); }
  .spoiler-block.active .show-link .show {
    display: none; }
  .spoiler-block.active .show-link .hidden {
    display: block; }

.counter-block {
  display: inline-block;
  vertical-align: top;
  font-size: 14px; }
  .counter-block span {
    vertical-align: middle;
    display: inline-block;
    margin: 0 4px;
    color: #4f5977; }
    .counter-block span.icon {
      cursor: pointer; }
      .counter-block span.icon:hover {
        color: #52709c; }
    .counter-block span.icon-minus.active {
      color: #b8d5ff; }
    .counter-block span.icon-plus.active {
      color: #43b85b; }
    .counter-block span.counter {
      overflow: hidden;
      height: 14px; }
      .counter-block span.counter .counter-all {
        font-size: 16px;
        margin-top: -14px;
        transform: translateZ(0);
        transition: all 0.5s ease; }
        .counter-block span.counter .counter-all span {
          display: block;
          line-height: 14px; }
          .counter-block span.counter .counter-all span.minus {
            color: #b8d5ff; }
          .counter-block span.counter .counter-all span.plus {
            color: #43b85b; }
        .counter-block span.counter .counter-all.minus {
          margin-top: 0; }
        .counter-block span.counter .counter-all.plus {
          margin-top: -41px; }
  .counter-block.small {
    font-size: 12px; }
    .counter-block.small span {
      vertical-align: middle;
      display: inline-block;
      margin: 0 3px; }
      .counter-block.small span.counter {
        overflow: hidden;
        height: 14px; }
        .counter-block.small span.counter .counter-all {
          font-size: 14px; }
          .counter-block.small span.counter .counter-all.minus {
            margin-top: 0; }
          .counter-block.small span.counter .counter-all.plus {
            margin-top: -40px; }

.tab-panel .nav-tabs {
  list-style: none;
  padding: 0;
  margin: 0 0 15px 0;
  font-size: 16px;
  line-height: normal;
  background-color: #0d1221;
  *zoom: 1; }
  .tab-panel .nav-tabs:before, .tab-panel .nav-tabs:after {
    display: table;
    content: "";
    line-height: 0; }
  .tab-panel .nav-tabs:after {
    clear: both; }
  .tab-panel .nav-tabs .desktop {
    display: block; }
  .tab-panel .nav-tabs li {
    float: left; }
    .tab-panel .nav-tabs li a {
      display: block;
      padding: 12px 16px; }
      .tab-panel .nav-tabs li a:hover {
        color: #fff;
        background-color: #52709c; }
    .tab-panel .nav-tabs li.active a {
      color: #fff;
      background-color: #233056; }
  .tab-panel .nav-tabs.nav-justified {
    width: 100%;
    display: flex; }
    .tab-panel .nav-tabs.nav-justified li {
      flex: 1; }
      .tab-panel .nav-tabs.nav-justified li a {
        text-align: center; }

.tab-panel .tab-content {
  font-size: 14px;
  padding: 0 16px; }
  .tab-panel .tab-content .tab-pane {
    display: none;
    visibility: hidden; }
    .tab-panel .tab-content .tab-pane.active {
      display: block;
      visibility: visible; }

.gg-calendar {
  position: relative; }
  .gg-calendar input {
    padding: 7px 33px 7px 11px; }
  .gg-calendar .icon-calendar {
    position: absolute;
    top: 9px;
    right: 12px;
    font-size: 17px;
    cursor: pointer; }
  .gg-calendar .calendar-block {
    position: absolute;
    z-index: 10;
    width: 244px; }

.calendar-block {
  color: #fff;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.3), 0 6px 6px 0 rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  background-color: #1c2644; }
  .calendar-block .flex {
    display: flex;
    flex-flow: row nowrap; }
    .calendar-block .flex .element {
      flex-shrink: 0;
      height: 246px; }
      .calendar-block .flex .element.calendar {
        width: 238px; }
      .calendar-block .flex .element.featured {
        width: 265px; }
  .calendar-block .tse-scrollbar .drag-handle {
    width: 6px;
    border-radius: 3px;
    background-color: #6e7895; }
  .calendar-block .control-block {
    padding: 20px 12px 0;
    font-weight: 600;
    text-align: center;
    margin-bottom: 5px;
    cursor: default; }
    .calendar-block .control-block .arrow {
      position: relative;
      padding: 5px 7px 5px 8px;
      cursor: pointer; }
      .calendar-block .control-block .arrow.left {
        float: left; }
      .calendar-block .control-block .arrow.right {
        float: right; }
  .calendar-block .months-wrap {
    padding: 0 10px; }
    .calendar-block .months-wrap .months th, .calendar-block .months-wrap .months td {
      text-align: center;
      width: 32px;
      height: 32px; }
    .calendar-block .months-wrap .months th {
      cursor: default;
      font-weight: 600; }
    .calendar-block .months-wrap .months td {
      cursor: pointer;
      border-radius: 2px; }
      .calendar-block .months-wrap .months td.other-month {
        opacity: 0.3; }
      .calendar-block .months-wrap .months td:hover, .calendar-block .months-wrap .months td.active {
        background-color: #52709c; }
  .calendar-block .time-picker-block {
    position: relative;
    margin-top: 18px;
    padding: 6px 40px;
    background-color: #18203a; }
    .calendar-block .time-picker-block:before {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -13px -1px;
      content: ":";
      font-size: 18px;
      line-height: 24px;
      font-weight: 600; }
    .calendar-block .time-picker-block .time-picker {
      text-align: center; }
      .calendar-block .time-picker-block .time-picker .arrow {
        padding: 6px;
        display: inline-block;
        vertical-align: top;
        cursor: pointer; }
      .calendar-block .time-picker-block .time-picker input[type="text"],
      .calendar-block .time-picker-block .time-picker input[type="number"] {
        margin: 7px 0;
        padding: 5px 10px;
        width: 42px;
        height: 40px;
        font-size: 18px;
        line-height: 24px;
        font-weight: 600; }
      .calendar-block .time-picker-block .time-picker input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0; }

.ui-progress canvas {
  position: absolute; }

.ui-switch {
  position: relative;
  background-color: #0d1221;
  margin: 0 24px 0 13px;
  border: none;
  box-shadow: none;
  padding: 10px 0;
  border-radius: 40px;
  color: #fff; }
  .ui-switch a {
    color: #fff;
    padding: 10px 16px;
    position: relative;
    z-index: 1;
    margin: 0 !important; }
  .ui-switch .active {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: auto;
    height: 100%;
    border-radius: 40px;
    background-color: #53709B; }

.popup-block-new {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10; }
  .popup-block-new .popup-inner {
    position: absolute;
    top: 60px;
    left: 50%;
    width: 100%;
    max-width: 768px;
    min-width: 320px;
    margin-bottom: 60px;
    transform: translate(-50%, 0); }
  .popup-block-new .icon-close3 {
    position: absolute;
    top: 24px;
    right: 24px;
    color: #fff;
    font-size: 18px;
    cursor: pointer; }
    .popup-block-new .icon-close3:hover {
      color: #73adff; }
  @media (max-width: 768px) {
    .popup-block-new .popup-inner {
      top: 0; } }

.task-modal {
  padding: 24px;
  color: #fff;
  background-color: #0d1221; }
  .task-modal .mobile-switch {
    max-width: 245px; }

.mobile-switch {
  width: 100%;
  display: flex;
  background-color: #0d1221;
  padding: 2px;
  border-radius: 100px; }
  .mobile-switch a {
    padding: 4px 16px;
    width: 50%;
    text-align: center;
    white-space: nowrap; }
    .mobile-switch a.active {
      color: #fff;
      background-color: #52709c;
      border-radius: 20px; }
      .mobile-switch a.active.inner {
        background-color: transparent;
        box-shadow: inset 0 0 0 2px #52709c; }

.flex {
  display: flex; }

.align-items-start {
  align-items: flex-start; }

.align-items-end {
  align-items: flex-end; }

.align-items-center {
  align-items: center; }

.align-items-baseline {
  align-items: baseline; }

.align-items-stretch {
  align-items: stretch; }

.justify-content-start {
  justify-content: flex-start; }

.justify-content-end {
  justify-content: flex-end; }

.justify-content-center {
  justify-content: center; }

.justify-content-between {
  justify-content: space-between; }

.justify-content-around {
  justify-content: space-around; }

html, body {
  margin: 0;
  padding: 0;
  height: 100%; }

#frameLoader {
  display: none; }

*:focus {
  outline: none; }

body {
  overflow: hidden;
  background-color: #0d1221; }
  body .ui-front {
    position: relative; }
  body .outer-wrap {
    position: relative;
    height: 100%;
    /* min-width: 1000px; */
    overflow: hidden; }
    body .outer-wrap .global-loader {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 101;
      height: 2px;
      display: block; }
      body .outer-wrap .global-loader .left, body .outer-wrap .global-loader .right {
        position: absolute;
        background: #43b85b;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0; }
      body .outer-wrap .global-loader .left {
        right: 50%;
        left: 50%; }
      body .outer-wrap .global-loader .right {
        right: 50%;
        left: 50%; }
    @media (max-width: 768px) {
      body .outer-wrap.new-feed__channel .feed-block .tab-panel .nav-tabs.nav-justified {
        background-color: #233055; } }
    @media (max-width: 1190px) {
      body .outer-wrap.new-feed__channel .feed-block .tab-panel .nav-tabs.nav-justified li.stream {
        display: none; } }
    @media (max-width: 768px) {
      body .outer-wrap.new-feed__channel .feed-block .tab-panel .nav-tabs.nav-justified li.stream {
        display: none; } }
    @media (max-width: 768px) {
      body .outer-wrap.new-feed__channel header {
        background-color: #233055; } }
    body .outer-wrap.loading .global-loader .left {
      transition: left 2s ease;
      left: 3%; }
    body .outer-wrap.loading .global-loader .right {
      transition: right 2s ease;
      right: 3%; }
    body .outer-wrap.loaded .global-loader .left {
      transition: right .5s ease-in .3s, left .3s ease;
      left: 0;
      right: 100%; }
    body .outer-wrap.loaded .global-loader .right {
      transition: left .5s ease-in .3s, right .3s ease;
      left: 100%;
      right: 0; }
  body.noanim header, body.noanim .feed-block, body.noanim .content-container, body.noanim .main-inner-wrap {
    transition: none !important; }
  body.no-ads .main-block .main-inner-wrap {
    margin-top: 0; }
  body.no-ads .banner-block {
    display: none; }
  body.no-ads .main-inner-wrap {
    max-width: inherit; }
  body.no-ads .wrapper {
    background-image: none; }
  body.hidden header {
    width: calc(100% - 240px);
    transition: width 0.5s ease;
    z-index: 8; }
    body.hidden header.wide {
      width: 100%;
      z-index: 8; }
    @media (max-width: 1190px) {
      body.hidden header {
        width: calc(100% - 240px); } }
  body.hidden .wrapper {
    background-position: 50% top !important; }
    @media (min-width: 2000px) {
      body.hidden .wrapper {
        margin: 0 auto;
        max-width: 2000px; } }
  body.hidden .banner-block.head .aside-block {
    display: block; }
  body.hidden .main-block {
    margin: 0; }
    body.hidden .main-block .promo-block, body.hidden .main-block .main-inner-wrap {
      width: auto; }
    body.hidden .main-block .promo-block {
      transform: translate(175px, 0); }
    body.hidden .main-block .main-inner-wrap {
      margin: 0 auto;
      z-index: 3; }
  body.hidden.no-ads .main-block .main-inner-wrap {
    margin: 0 auto;
    z-index: 3; }
  body.hidden .feed-block {
    width: 50px;
    height: 48px;
    overflow: hidden;
    z-index: 7; }
    body.hidden .feed-block.wide {
      width: 240px; }
    body.hidden .feed-block .stream-block .chat {
      overflow: hidden; }
    @media (max-width: 1190px) {
      body.hidden .feed-block {
        width: 210px; } }
  @media (max-width: 1190px) {
    body.hidden .main-block {
      margin: 0; }
      body.hidden .main-block .promo-block {
        margin: 0; } }
  body.scrollable {
    overflow: auto; }
  @media only screen and (min-resolution: 2dppx) {
    body {
      /* font-weight: 200; */ } }

@media (max-width: 360px) {
  .old-site {
    overflow-x: scroll; } }

.wrapper {
  position: relative;
  margin: 0 auto;
  max-width: 2000px;
  height: 100%;
  background: none center top no-repeat;
  background-position: calc(50% - 175px) top !important;
  transition: background-position 0.5s ease; }
  @media (min-width: 2000px) {
    .wrapper {
      background-position: 50% top !important;
      max-width: none;
      margin: 0;
      width: calc(100% - 350px); } }

.wrapper__max-width {
  max-width: none;
  width: 100%; }
  @media (min-width: 2000px) {
    .wrapper__max-width {
      background-position: calc(50% - 175px) top !important; } }
  .wrapper__max-width .channel-wrapper {
    max-width: none; }

header {
  position: relative;
  width: calc(100% - 350px);
  height: 48px;
  transition: width 0.5s ease;
  background-color: #233056;
  box-shadow: -3px 1px 2px rgba(0, 0, 0, 0.8);
  z-index: 7; }
  header .wrap {
    position: relative;
    margin: 0 auto; }
  header.wide {
    width: 100%; }
  @media (max-width: 1190px) {
    header {
      width: calc(100% - 210px); } }

.main-block {
  position: relative;
  width: 100%;
  height: calc(100% - 48px); }
  .main-block .promo-block {
    position: relative;
    width: calc(100% - 350px);
    height: 200px;
    transition: margin 0.5s ease; }
  .main-block .main-inner-wrap {
    position: relative;
    padding: .993% .993% 0;
    width: calc(100% - 350px);
    max-width: 1570px;
    left: 0;
    transform: translate(0, 0);
    background-color: #0D1221; }
    @media (min-width: 2000px) {
      .main-block .main-inner-wrap {
        margin: 0 auto; } }
    .main-block .main-inner-wrap.new-color__channel {
      background-color: #0C0F12;
      max-width: none;
      margin: 0; }
      @media (min-width: 2000px) {
        .main-block .main-inner-wrap.new-color__channel {
          width: 100%; } }
  .main-block .tse-scrollable {
    height: 100%; }
    .main-block .tse-scrollable.scroller {
      padding: 0;
      margin: 0; }
  .main-block .tse-scroll-content {
    will-change: scroll-position; }
  .main-block .adaptive-flex__wrapper {
    display: flex; }
    .main-block .adaptive-flex__wrapper .adaptive-wrapper {
      width: auto;
      margin: 0 1.2% 2% 90px; }
      @media (max-width: 1850px) {
        .main-block .adaptive-flex__wrapper .adaptive-wrapper {
          margin: 0 1.2% 2% 0; } }
  .main-block .left-block-wrap {
    width: auto; }
    .main-block .left-block-wrap .news-block {
      width: 700px;
      max-width: 700px; }
      @media (max-width: 1300px) {
        .main-block .left-block-wrap .news-block {
          width: 600px; } }
      @media (max-width: 1190px) {
        .main-block .left-block-wrap .news-block {
          width: 700px; } }
  .main-block .banner-news-wrap {
    width: 60.55%;
    float: left;
    margin: 0;
    padding: 0 1.6% 1.181% 0; }
    .main-block .banner-news-wrap iframe, .main-block .banner-news-wrap img {
      display: block;
      margin: 0 auto; }
  .main-block .big-block-wrap {
    position: relative;
    width: 100%; }
    .main-block .big-block-wrap .tournaments-wrap {
      margin: 0 0 1.181% 0;
      width: 100%; }
      .main-block .big-block-wrap .tournaments-wrap .tournaments-block {
        margin-bottom: 3.05%;
        border-radius: 4px;
        background-color: #151c34; }
  .main-block .small-block-wrap {
    width: 100%; }
    .main-block .small-block-wrap .forum-wrap, .main-block .small-block-wrap .broadcasts-wrap {
      position: relative;
      width: 50%;
      margin: 0 0 3.354% 0; }
      .main-block .small-block-wrap .forum-wrap .forum-block, .main-block .small-block-wrap .forum-wrap .broadcasts-block, .main-block .small-block-wrap .broadcasts-wrap .forum-block, .main-block .small-block-wrap .broadcasts-wrap .broadcasts-block {
        position: relative;
        border-radius: 4px;
        background-color: #151c34; }
      .main-block .small-block-wrap .forum-wrap .forum-block, .main-block .small-block-wrap .broadcasts-wrap .forum-block {
        margin-right: 3.05%; }
      .main-block .small-block-wrap .forum-wrap .broadcasts-block, .main-block .small-block-wrap .broadcasts-wrap .broadcasts-block {
        margin-left: 3.05%; }
    .main-block .small-block-wrap .video-wrap {
      position: relative;
      margin: 0 0 3.354% 0; }
      .main-block .small-block-wrap .video-wrap .video-block {
        border-radius: 4px;
        background-color: #151c34; }
    .main-block .small-block-wrap .gallery-wrap {
      position: relative;
      margin: 0 0 3.354% 0;
      border-radius: 4px;
      background-color: #151c34; }
  .main-block .order-block {
    display: flex;
    justify-content: center; }
    @media (max-width: 1750px) {
      .main-block .order-block {
        flex-direction: column; } }
    .main-block .order-block .adaptive-block {
      display: none; }
      @media (max-width: 1750px) {
        .main-block .order-block .adaptive-block {
          display: flex; } }
    .main-block .order-block .flex-wrapper {
      display: flex;
      flex-direction: column;
      margin-right: 75px;
      width: 635px;
      max-width: 635px;
      margin-top: 13px; }
      @media (max-width: 1850px) {
        .main-block .order-block .flex-wrapper {
          margin-right: 20px; } }
      @media (max-width: 1750px) {
        .main-block .order-block .flex-wrapper {
          margin-right: 0;
          width: auto; } }
      @media (max-width: 1400px) {
        .main-block .order-block .flex-wrapper {
          margin-top: 0; } }
      @media (max-width: 1750px) {
        .main-block .order-block .flex-wrapper .big-block-wrap {
          display: none; } }
    @media (max-width: 950px) {
      .main-block .order-block .adaptive-wrapper {
        display: flex; } }

.hidden .main-inner-wrap .adaptive-flex__wrapper .adaptive-wrapper {
  margin: 0 1.2% 2% 90px; }
  @media (max-width: 1400px) {
    .hidden .main-inner-wrap .adaptive-flex__wrapper .adaptive-wrapper {
      margin: 0 1.2% 2% 0; } }

@media (max-width: 1300px) {
  .hidden .main-inner-wrap .left-block-wrap .news-block {
    width: 700px; } }

@media (max-width: 950px) {
  .hidden .main-inner-wrap .left-block-wrap .news-block {
    width: auto;
    max-width: none; } }

.hidden .main-inner-wrap .flex-wrapper {
  margin-right: 75px; }
  @media (max-width: 1400px) {
    .hidden .main-inner-wrap .flex-wrapper {
      margin-right: 0; } }
  .hidden .main-inner-wrap .flex-wrapper .big-block-wrap {
    display: block; }
    @media (max-width: 1400px) {
      .hidden .main-inner-wrap .flex-wrapper .big-block-wrap {
        display: none; } }

.hidden .main-inner-wrap .adaptive-block {
  display: none; }
  @media (max-width: 1400px) {
    .hidden .main-inner-wrap .adaptive-block {
      display: flex; } }

@media (max-width: 1400px) {
  .hidden .main-inner-wrap .order-block {
    flex-direction: column; } }

.feed-block {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 350px;
  will-change: transform;
  transform: translate(0, 0);
  transition: transform 0.5s ease, width 0.5s ease; }
  .feed-block .resizer {
    position: absolute;
    display: none;
    top: 0;
    left: -8px;
    bottom: 0;
    width: 10px;
    cursor: move;
    border-right: 2px solid #233056;
    transition: .2s border;
    z-index: 2; }
    .feed-block .resizer:hover {
      border-color: #52709c; }
  .feed-block .ghost-block {
    will-change: transform;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-width: 1px;
    background: rgba(82, 112, 156, 0.25);
    pointer-events: none;
    opacity: 0;
    transition: .2s opacity,.2s background;
    z-index: 2; }
  .feed-block .opener {
    display: none;
    position: absolute;
    top: 50%;
    margin-top: -18px;
    left: 0;
    width: 35px;
    height: 35px;
    font-family: "icomoon";
    font-size: 18px;
    color: #fff;
    text-align: center;
    line-height: 35px;
    background-color: #233056;
    border-radius: 2px;
    transform: translate(0, 0);
    transition: .3s all;
    cursor: pointer;
    z-index: 2; }
    .feed-block .opener:hover {
      background-color: #52709c; }
    .feed-block .opener:before {
      content: "\e901"; }
  .feed-block .head-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    transform: translate(0, 0);
    transition: transform 0.5s ease; }
  .feed-block .content-container {
    position: absolute;
    top: 48px;
    right: 0;
    width: 100%;
    height: calc( 100% - 48px);
    background-color: #1c2644; }
  @media (max-width: 1190px) {
    .feed-block {
      width: 210px; }
      .feed-block .content-container {
        width: 350px; } }
  .feed-block.resizing {
    user-select: none; }
    .feed-block.resizing .ghost-block {
      opacity: 1; }
    .feed-block.resizing.close .ghost-block {
      background-color: rgba(228, 45, 45, 0.25); }
  .feed-block.closed .chat-container {
    padding: 0; }

.feed-block-switcher {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  border-right: 3px solid transparent;
  height: 100%;
  cursor: pointer;
  text-align: center;
  transition: transform 0.5s ease;
  z-index: 8; }
  .feed-block-switcher:hover {
    border-color: #52709c; }
    .feed-block-switcher:hover .icon-arrow-left {
      display: block; }
    .feed-block-switcher:hover .icon {
      background-color: #52709c; }
  .feed-block-switcher .icon {
    position: absolute;
    top: 50%;
    right: -14px;
    vertical-align: top;
    color: #fff;
    border-radius: 50%;
    background-color: rgba(52, 71, 127, 0.5);
    padding: 8px 10px 8px 10px;
    display: none; }
    .feed-block-switcher .icon.icon-arrow-left {
      padding: 8px 10px 8px 11px; }

.likely.likely_gg .likely__icon {
  display: none; }

.likely.likely_gg .likely__widget {
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: top;
  padding: 0 2px;
  background-color: #fff;
  border-radius: 2px;
  cursor: pointer; }
  .likely.likely_gg .likely__widget:not(:last-child) {
    margin: 0 12px 0 0; }
  .likely.likely_gg .likely__widget .likely__button {
    color: #fff; }
  @media (max-width: 768px) {
    .likely.likely_gg .likely__widget_vkontakte {
      padding-top: 2px; } }
  .likely.likely_gg .likely__widget_vkontakte {
    background-color: #4680C2; }
    .likely.likely_gg .likely__widget_vkontakte:hover {
      background-color: #77A3D5; }
  .likely.likely_gg .likely__widget_twitter {
    background-color: #5d9ec9; }
    .likely.likely_gg .likely__widget_twitter:hover {
      background-color: #69adda; }
  .likely.likely_gg .likely__widget_odnoklassniki {
    background-color: #eb722e;
    display: none; }
    .likely.likely_gg .likely__widget_odnoklassniki:hover {
      background-color: #f4874a; }
  .likely.likely_gg .likely__widget_facebook {
    background-color: #1877F2; }
    .likely.likely_gg .likely__widget_facebook:hover {
      background-color: #4D8BDC; }
  .likely.likely_gg .likely__widget .icon {
    font-size: 16px;
    vertical-align: middle;
    margin-left: 2px; }
    .likely.likely_gg .likely__widget .icon.icon-twitter {
      display: inline-block;
      vertical-align: bottom;
      margin: 2px 0 0;
      margin-left: 3px;
      font-size: 13px; }
    .likely.likely_gg .likely__widget .icon.icon-odnoklassniki {
      display: inline-block;
      vertical-align: top;
      margin: 2px 1px 0 4px;
      padding-bottom: 4px;
      font-size: 22px; }
    .likely.likely_gg .likely__widget .icon.icon-facebook {
      display: inline-block;
      vertical-align: bottom;
      margin: 1px -2px -5px 0px;
      font-size: 20px; }
  @media (max-width: 768px) {
    .likely.likely_gg .likely__widget .icon {
      vertical-align: sub; } }
  .likely.likely_gg .likely__widget .text {
    display: inline-block;
    vertical-align: top;
    margin: 3px 0 0 6px; }
  .likely.likely_gg .likely__widget .likely__counter {
    display: none;
    vertical-align: top;
    margin: 3px 3px 0 0;
    font-weight: 600; }

@media (min-width: 1870px) {
  .main-inner-wrap .tournaments-block .tournaments-list {
    justify-content: space-between; }
    .main-inner-wrap .tournaments-block .tournaments-list .tournament {
      width: 45%; } }

@media (min-width: 1190px) and (max-width: 1750px) {
  .main-inner-wrap {
    padding: 1.045% 1.045% 0; }
    .main-inner-wrap .banner-news-wrap {
      width: 100%;
      float: none;
      margin: 0 0 1.181%;
      padding: 0; }
    .main-inner-wrap .big-block-wrap .tournaments-wrap .tournaments-block {
      margin-bottom: 1.174%; }
    .main-inner-wrap .tournaments-block .tournaments-list {
      justify-content: space-between; }
      .main-inner-wrap .tournaments-block .tournaments-list .tournament {
        width: calc(32% - 24px);
        margin: 0 20px 24px 0;
        vertical-align: top;
        display: inline-block;
        text-align: left;
        zoom: 1; } }

@media (max-width: 1600px) {
  .main-inner-wrap .small-block-wrap .forum-wrap, .main-inner-wrap .small-block-wrap .broadcasts-wrap {
    width: 100%; }
  .main-inner-wrap .small-block-wrap .broadcasts-wrap .broadcasts-block {
    margin-left: 0; }
  .main-inner-wrap .small-block-wrap .forum-wrap .forum-block {
    margin-right: 0; } }

@media (min-width: 1400px) and (max-width: 1870px) {
  .hidden .main-inner-wrap .banner-news-wrap {
    width: 100%;
    float: none;
    margin: 0 0 1.181%;
    padding: 0; }
  .hidden .main-inner-wrap .big-block-wrap .tournaments-wrap .tournaments-block {
    margin-bottom: 3.05%; }
  .hidden .main-inner-wrap .small-block-wrap .forum-wrap, .hidden .main-inner-wrap .small-block-wrap .broadcasts-wrap {
    width: 50%; }
  .hidden .main-inner-wrap .small-block-wrap .broadcasts-wrap .broadcasts-block {
    margin-left: 3.05%; }
  .hidden .main-inner-wrap .small-block-wrap .forum-wrap .forum-block {
    margin-right: 3.05%; }
  .hidden .main-inner-wrap .tournaments-block .tournaments-list {
    justify-content: space-between; }
    .hidden .main-inner-wrap .tournaments-block .tournaments-list .tournament {
      width: 45%; }
  .hidden .main-inner-wrap .video-wrap .video-block .video-list .video {
    position: relative;
    width: 48%;
    margin: 0 0 4% 0;
    position: relative; }
    .hidden .main-inner-wrap .video-wrap .video-block .video-list .video:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 56.25%; }
    .hidden .main-inner-wrap .video-wrap .video-block .video-list .video > .link {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    .hidden .main-inner-wrap .video-wrap .video-block .video-list .video img {
      width: 100%;
      height: 100%; }
    .hidden .main-inner-wrap .video-wrap .video-block .video-list .video .img-block {
      display: block;
      width: 100%;
      height: 100%; }
      .hidden .main-inner-wrap .video-wrap .video-block .video-list .video .img-block .gradient-block {
        display: none; }
    .hidden .main-inner-wrap .video-wrap .video-block .video-list .video .link .info {
      position: absolute;
      width: 100%;
      height: 50%;
      left: 0;
      bottom: 0;
      font-size: 14px;
      line-height: 21px;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000 100%); }
      .hidden .main-inner-wrap .video-wrap .video-block .video-list .video .link .info .user, .hidden .main-inner-wrap .video-wrap .video-block .video-list .video .link .info .text {
        position: absolute;
        display: block;
        left: 10px;
        right: 10px;
        bottom: 8px;
        color: #fff;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 0; }
      .hidden .main-inner-wrap .video-wrap .video-block .video-list .video .link .info .text {
        font-size: 14px;
        line-height: 21px; }
      .hidden .main-inner-wrap .video-wrap .video-block .video-list .video .link .info .user {
        color: #73adff;
        bottom: 30px; }
      .hidden .main-inner-wrap .video-wrap .video-block .video-list .video .link .info .view-count {
        display: none;
        color: #585f73; }
    .hidden .main-inner-wrap .video-wrap .video-block .video-list .video .link:hover .img-block {
      color: #fff;
      position: relative;
      filter: brightness(1.2); } }
    @media screen\0  and (min-width: 1400px) and (max-width: 1870px) {
      .hidden .main-inner-wrap .video-wrap .video-block .video-list .video .link:hover .img-block:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.2); } }

@media (min-width: 1400px) and (max-width: 1870px) {
      .hidden .main-inner-wrap .video-wrap .video-block .video-list .video .link:hover .img-block .icon-video-icon {
        color: #fff; } }

@media (max-width: 1190px) {
  .main-block .main-inner-wrap {
    width: 100%;
    transform: translate(0, 0); }
    .main-block .main-inner-wrap .tournaments-block .tournaments-list {
      justify-content: space-between; }
      .main-block .main-inner-wrap .tournaments-block .tournaments-list .tournament {
        width: calc(32% - 24px); }
  .main-block .stream-selector {
    margin: 0 0 0.9% 0; }
  .main-block .big-block-wrap .tournaments-wrap {
    width: 100%; }
    .main-block .big-block-wrap .tournaments-wrap .tournaments-block {
      margin-bottom: 0.9%; }
  .main-block .small-block-wrap .forum-wrap, .main-block .small-block-wrap .broadcasts-wrap {
    width: 100%; }
    .main-block .small-block-wrap .forum-wrap .forum-block, .main-block .small-block-wrap .forum-wrap .broadcasts-block, .main-block .small-block-wrap .broadcasts-wrap .forum-block, .main-block .small-block-wrap .broadcasts-wrap .broadcasts-block {
      margin: 0 0 3% 0; } }

@media (max-width: 768px) {
  .main-block .main-inner-wrap.new-color__channel {
    width: 100%;
    height: 100%; }
    .main-block .main-inner-wrap.new-color__channel #entire-wrapper {
      height: 100%; }
      .main-block .main-inner-wrap.new-color__channel #entire-wrapper #entire {
        height: 100%; }
        .main-block .main-inner-wrap.new-color__channel #entire-wrapper #entire > div {
          height: 100%; }
          .main-block .main-inner-wrap.new-color__channel #entire-wrapper #entire > div .channel-wrap .chat-height__wrapper {
            height: 100%;
            display: flex;
            flex-direction: column; } }

/*
  Блок логина
*/
.login-wrap-big {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(13, 18, 33, 0.6);
  z-index: 10; }

.login-wrap-block {
  position: absolute;
  top: 10vh;
  left: 50%;
  width: 360px;
  transform: translate(-50%, -50%);
  z-index: 10; }
  .login-wrap-block .inner-wrap {
    position: absolute; }
  .login-wrap-block .icon-close2 {
    position: absolute;
    top: 0;
    right: -45px;
    font-size: 20px;
    color: #dfecff;
    cursor: pointer; }
  .login-wrap-block .step-block {
    position: absolute;
    top: 46%;
    width: 360px;
    overflow: hidden;
    background-color: #233056;
    transition: height .15s ease-in-out;
    transition-delay: .2s; }
    .login-wrap-block .step-block .system-message {
      margin-bottom: 3.1%; }
    .login-wrap-block .step-block .step {
      position: absolute;
      padding: 13.89%;
      width: 100%;
      border-radius: 2px;
      opacity: 0;
      transition: opacity .2s ease-in-out; }
      .login-wrap-block .step-block .step .btn {
        width: 100%; }
      .login-wrap-block .step-block .step .text {
        margin-bottom: 6.923%; }
      .login-wrap-block .step-block .step .login-block {
        margin-bottom: 9.231%; }
      .login-wrap-block .step-block .step .checkbox {
        margin-bottom: 9.23%; }
      .login-wrap-block .step-block .step:first-child {
        padding-bottom: 8.05%; }
      .login-wrap-block .step-block .step.link-social .flex, .login-wrap-block .step-block .step.remember-step .flex {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between; }
      .login-wrap-block .step-block .step.link-social .title, .login-wrap-block .step-block .step.remember-step .title {
        margin-bottom: 4.615%; }
      .login-wrap-block .step-block .step.link-social .btn, .login-wrap-block .step-block .step.remember-step .btn {
        width: 45%;
        text-align: center;
        padding-left: 0;
        padding-right: 0; }
    .login-wrap-block .step-block.opened .step {
      transition: none !important; }
    .login-wrap-block .step-block.one {
      height: 570px; }
      .login-wrap-block .step-block.one .step:first-child {
        opacity: 1;
        z-index: 1;
        transition: opacity .2s ease-in-out;
        transition-delay: .35s; }
    .login-wrap-block .step-block.login-step-twitch {
      height: 530px; }
      .login-wrap-block .step-block.login-step-twitch.warn-email {
        height: 586px; }
      .login-wrap-block .step-block.login-step-twitch.warn-auth {
        height: 607px; }
      .login-wrap-block .step-block.login-step-twitch.warn-email.warn-auth {
        height: 665px; }
      .login-wrap-block .step-block.login-step-twitch.second {
        height: 358px; }
        .login-wrap-block .step-block.login-step-twitch.second.warn-nick {
          height: 445px; }
      .login-wrap-block .step-block.login-step-twitch .login-step-twitch {
        opacity: 1;
        z-index: 1;
        transition: opacity .2s ease-in-out;
        transition-delay: .35s; }
    .login-wrap-block .step-block.login-step {
      height: 525px; }
      .login-wrap-block .step-block.login-step .login-step {
        opacity: 1;
        z-index: 1;
        transition: opacity .2s ease-in-out;
        transition-delay: .35s; }
    .login-wrap-block .step-block.registration-step {
      height: 740px; }
      .login-wrap-block .step-block.registration-step .registration-step {
        opacity: 1;
        z-index: 1;
        transition: opacity .2s ease-in-out;
        transition-delay: .35s; }
    .login-wrap-block .step-block.link-social {
      height: 467px; }
      .login-wrap-block .step-block.link-social .link-social {
        opacity: 1;
        z-index: 1;
        transition: opacity .2s ease-in-out;
        transition-delay: .35s; }
    .login-wrap-block .step-block.registration-final {
      height: 338px; }
      .login-wrap-block .step-block.registration-final .registration-final {
        opacity: 1;
        z-index: 1;
        transition: opacity .2s ease-in-out;
        transition-delay: .35s; }
    .login-wrap-block .step-block.very-final {
      height: 424px; }
      .login-wrap-block .step-block.very-final .very-final {
        opacity: 1;
        z-index: 1;
        transition: opacity .2s ease-in-out;
        transition-delay: .35s; }
    .login-wrap-block .step-block.remember-step {
      height: 360px; }
      .login-wrap-block .step-block.remember-step .remember-step {
        opacity: 1;
        z-index: 1;
        transition: opacity .2s ease-in-out;
        transition-delay: .35s; }
    .login-wrap-block .step-block.remember-finish {
      height: 248px; }
      .login-wrap-block .step-block.remember-finish .remember-finish {
        opacity: 1;
        z-index: 1;
        transition: opacity .2s ease-in-out;
        transition-delay: .35s; }
    .login-wrap-block .step-block.remember-ok {
      height: 267px; }
      .login-wrap-block .step-block.remember-ok .remember-ok {
        opacity: 1;
        z-index: 1;
        transition: opacity .2s ease-in-out;
        transition-delay: .35s; }
    .login-wrap-block .step-block.remember-error {
      height: 267px; }
      .login-wrap-block .step-block.remember-error .remember-error {
        opacity: 1;
        z-index: 1;
        transition: opacity .2s ease-in-out;
        transition-delay: .35s; }
  .login-wrap-block .login-hint-block {
    color: #fff;
    margin: 0 0 6.667%; }
    .login-wrap-block .login-hint-block .logo-block {
      margin-bottom: 17.692%; }
    .login-wrap-block .login-hint-block img {
      display: block;
      margin: 0 auto; }
    .login-wrap-block .login-hint-block svg {
      width: 100%;
      height: 61px; }
  .login-wrap-block .login-list {
    width: 100%;
    margin: 0 auto 9.231%; }
    .login-wrap-block .login-list .btn {
      display: block;
      margin: 0 auto 4.615%;
      width: 100%;
      font-weight: 600;
      padding: 3% 2.308% 3% 3.1%;
      line-height: 23px;
      border-radius: 2px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12); }
      .login-wrap-block .login-list .btn:before {
        content: '';
        display: inline-block;
        vertical-align: top;
        width: 24px;
        height: 24px;
        margin-right: 7.6%; }
      .login-wrap-block .login-list .btn.btn-vk2 {
        background-color: #4c6c91; }
        .login-wrap-block .login-list .btn.btn-vk2:before {
          background: url("/images/svg/social/vk-24-px.svg"); }
        .login-wrap-block .login-list .btn.btn-vk2:hover {
          background-color: #6385ad; }
      .login-wrap-block .login-list .btn.btn-twitch {
        background-color: #6441a4; }
        .login-wrap-block .login-list .btn.btn-twitch:before {
          background: url("/images/svg/social/twitch-24-px.svg"); }
        .login-wrap-block .login-list .btn.btn-twitch:hover {
          background-color: #7d5abe; }
      .login-wrap-block .login-list .btn.btn-fb2 {
        background-color: #3a599a; }
        .login-wrap-block .login-list .btn.btn-fb2:before {
          background: url("/images/svg/social/fb-24-px.svg"); }
        .login-wrap-block .login-list .btn.btn-fb2:hover {
          background-color: #4c70bb; }
      .login-wrap-block .login-list .btn.btn-twitter {
        background-color: #55acee; }
        .login-wrap-block .login-list .btn.btn-twitter:before {
          background: url("/images/svg/social/twitter-24-px.svg"); }
        .login-wrap-block .login-list .btn.btn-twitter:hover {
          background-color: #83c3f3; }
      .login-wrap-block .login-list .btn.btn-google {
        color: rgba(0, 0, 0, 0.54);
        background-color: #fff; }
        .login-wrap-block .login-list .btn.btn-google:before {
          background: url("/images/svg/social/google-24-px.svg"); }
        .login-wrap-block .login-list .btn.btn-google:hover {
          color: rgba(0, 0, 0, 0.34); }
      .login-wrap-block .login-list .btn.btn-bnet {
        background-color: #153d5f; }
        .login-wrap-block .login-list .btn.btn-bnet:before {
          background: url("/images/svg/social/bnet-24-px.svg"); }
        .login-wrap-block .login-list .btn.btn-bnet:hover {
          background-color: #1e5889; }
      .login-wrap-block .login-list .btn.btn-od {
        background-color: #E7792B; }
        .login-wrap-block .login-list .btn.btn-od::before {
          background: url("/images/svg/social/od-24-px.svg"); }
        .login-wrap-block .login-list .btn.btn-od:hover {
          filter: brightness(1.2); }
  .login-wrap-block .link-list {
    text-align: center; }
    .login-wrap-block .link-list .link-block {
      margin-bottom: 18px; }
      .login-wrap-block .link-list .link-block:last-child {
        margin-bottom: 0; }
  .login-wrap-block .form-group {
    margin-bottom: 1.7143em; }
    .login-wrap-block .form-group label {
      position: relative;
      display: block;
      color: #fff;
      font-weight: 600;
      margin-bottom: 3.1%; }
      .login-wrap-block .form-group label:hover .tip {
        display: block;
        width: 260px;
        white-space: normal;
        left: 39%;
        top: -50px; }
  .login-wrap-block .login-block .btn-block {
    margin: 32px 0 41px; }
    .login-wrap-block .login-block .btn-block .btn {
      text-align: center;
      width: 90px;
      margin-right: 14px; }
  .login-wrap-block .login-block .captcha-block {
    margin: 13px 0 -20px 0; }
  .login-wrap-block .title {
    margin-bottom: 1em;
    white-space: nowrap;
    font-size: 1.7143em;
    font-weight: 600;
    color: #fff; }
  .login-wrap-block .captcha-block {
    margin: -3px 0 18px 2px;
    transform: scale(0.8515);
    transform-origin: 0 0; }
  .login-wrap-block .registration-block {
    width: 100%;
    margin: 0 auto 9.231%; }
    .login-wrap-block .registration-block .btn {
      text-align: center;
      width: 100%; }
  .login-wrap-block .remember-finish .text, .login-wrap-block .remember-ok .text, .login-wrap-block .remember-error .text {
    text-align: center; }
  .login-wrap-block .remember-finish .btn, .login-wrap-block .remember-ok .btn, .login-wrap-block .remember-error .btn {
    text-align: center;
    width: 80px;
    margin: 0 auto; }
  .login-wrap-block .last-step-block {
    color: #fff; }
    .login-wrap-block .last-step-block .text {
      margin-bottom: 0.77778em; }
    .login-wrap-block .last-step-block .btn {
      width: 100%;
      text-align: center; }
  @media only screen and (-webkit-min-device-pixel-ratio: 3), only screen and (min-resolution: 3dppx), only screen and (min-resolution: 350dpi) {
    .login-wrap-block .inner-wrap .step-block .step {
      padding: 24vh 30vw;
      transform: scale(1.8); } }
  @media (max-width: 767px) {
    .login-wrap-block {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: none; }
      .login-wrap-block .registration-block .captcha-block {
        width: 304px;
        transform: unset;
        transform-origin: unset;
        margin-left: auto;
        margin-right: auto; }
      .login-wrap-block .inner-wrap {
        width: 100%;
        height: 100%; }
        .login-wrap-block .inner-wrap .step-block {
          top: 0;
          width: 100%;
          height: 100%;
          overflow-y: auto; }
          .login-wrap-block .inner-wrap .step-block .step {
            padding: 20px;
            transform: none; }
            .login-wrap-block .inner-wrap .step-block .step.login-step .login-hint-block .title {
              margin-bottom: 0; }
            .login-wrap-block .inner-wrap .step-block .step.registration-step .step.registration-step {
              overflow-y: scroll;
              padding-bottom: 70px; }
              .login-wrap-block .inner-wrap .step-block .step.registration-step .step.registration-step .btn.fixed {
                position: fixed;
                bottom: 0;
                left: 0;
                border: solid #233056;
                border-width: 0 20px 20px 20px; }
      .login-wrap-block .icon-close2 {
        top: 15px;
        right: 15px;
        z-index: 2; } }
  @media only screen and (max-device-width: 360px) {
    .login-wrap-block .registration-block .captcha-block {
      width: 195px;
      transform: scale(0.8);
      margin-left: 0;
      margin-right: 0; } }
