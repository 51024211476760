@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin center(){
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@mixin aspect-ratio($width, $height, $name) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    //padding-top: 82.9%;
    padding-top: ($height / $width) * 100%;
  }
  > .#{$name} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin loading($bgcolor){
  &.loading {
    position: relative;

    &:before {
      position: absolute;
      top: 0;
      content: '';
      display: block;
      bottom: 0;
      right: 0;
      left: 0;
      background: $bgcolor url(/images/chat/ajax_loading.svg) no-repeat center;
      z-index: 9;
      opacity: 0.8;
    }
  }
}

@mixin img-hover(){
  position: relative;
  filter: brightness(1.2);
  @media screen\0 {
    &:after{
      content:'';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255,255,255, 0.2);
    }
  }
}

@mixin grid-line-top($beforeTop,$beforeHeight,$afterTop){
  &:before,&:after{
    content:"";
    position: absolute;
    right: -13px;
    width: 9px;
    border-width: 4px 4px 0 0;
    border-style: solid;
    border-color: $accent;
  }
  &:before{
    top: $beforeTop;
    height: $beforeHeight;
    border-radius: 0 2px 0 0;
  }
  &:after{
    top: $afterTop;
    right: -24px;
    width: 11px;
    border-radius: 0 0 0 2px;
  }
}
@mixin grid-line-bot($beforeTop,$beforeHeight,$afterTop){
  &:before,&:after{
    content:"";
    position: absolute;
    right: -13px;
    width: 9px;
    border-width: 0 4px 4px 0;
    border-style: solid;
    border-color: $accent;
  }
  &:before{
    top: $beforeTop;
    height: $beforeHeight;
    border-radius: 0 0 2px 0;
  }
  &:after{
    top: $afterTop;
    right: -24px;
    width: 11px;
    border-radius: 2px 0 0 0;
  }
}

@mixin set-complex-colors($leftColor, $rightColor) {
  .part {
    display: inline-block;
    padding: 8px 8px;
    &:nth-child(1) {
      background-color: $leftColor;
      border-radius: 2px 0 0 2px;
    }
    &:nth-child(2) {
      background-color: $rightColor;
      margin-left: -4px;
      border-radius: 0 2px 2px 0;
    }
  }
}

@mixin flex($flow: nowrap, $justify: flex-start, $align: stretch) {
  display: flex;
  flex-flow: row $flow;
  justify-content: $justify;
  align-items: $align;
}

@mixin text-overflow($diff: 0px) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width:calc(100% - #{$diff});
}