$white: #fff;
$veryLightBlue: #dfecff;
$linkBlue: #73adff;
$linkBlueHover: #4f98ff;
$normalText: #b8d5ff;
$green: #43b85b;
$softGreen: #68c67b;
$updGreen: #2C7F3D;
$orange: #fa963e;
$lightOrange: #faaa63;
$red: #fe4b4b;
$salmon: #fe6e6e;
$blue: #233055;
$darkBlue: #1C2643;
$darkBlueGrey: #17133c;
$dark: #151c34;
$mainColor: #233056;
$mainBackgr: #1c2644;
$black: #0d1221;
$darkestBlue: #0D1221;
$accent: #52709c;
$accentBright: #748caf;
$semi: #4f5977;
$blueGray: #768ba9;
$gray: #868890;


// Цвета для ников в чате
$bronzeNick : #e7820a;
$silverNick : #b4b4b4;
$goldNick: #eefc08;
$chatYellow: #F2F932;
$diamondNick: #8781bd;
$kingNick: #30d5c8;
$topNick: #3BCBFF;
$darkPink: #BD70D7;
$undead: #AB4873;
$premiumNick: #31a93a;
$moderatorNick: #ec4058;
$streamerNick: #e8bb00;



