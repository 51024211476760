
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  color: $white;
  font-size: 14px;
}

.ng-cloak {
  display: none;
}

* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5 {
  color: $white;
}

h1, .h1 {
  font-size: 34px;
  font-weight: 300;
  line-height: 46px;
}

h2, .h2 {
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
}

h3, .h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

ul {
  padding-left: 18px;
}

ol {
  padding-left: 20px;
}

p {
  color: $white;

  &.error {
    color: $red;
  }
}

a {
  color: $linkBlue;
  text-decoration: none;
  &:hover {
    color: #4f98ff;
  }
}

.mobile{
  display: none;
}

.semibold {
  font-weight: 600;
}

.rub-sign {
  position: relative;
  display: inline-block;
  line-height: 1;
  vertical-align: baseline;
  &:before {
    content: '\2212';
    font-size: 0.9em;
    position: absolute;
    top: 14%;
  }
  &:after {
    content: '\002D';
    position: absolute;
    top: 11%;
    left: 0;
  }
  &.semibold {
    font-weight: 600;
  }
  &.bold {
    font-weight: 700;
  }
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.ov {
  overflow: hidden;
}

.clearfix {
  &:before,
  &:after {
    display: table;
    content: "";
    line-height: 0;
  }
  &:after {
    clear: both;
  }
}

.form-group {
  position: relative;
  margin-bottom: 15px;
  label {
    display: inline-block;
    margin-bottom: 2px;
  }
}

.dropbox {
  position: relative;
  background-color: $black;
  border: 2px dashed #2b3f63;
  img {
    width: 100%;
    height: 100%;
  }
  &.ready {
    border: 2px dashed $linkBlue;
  }
  .base {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    background-color: rgba(13, 18, 33, 0.7);
    transition: transform 0.3s ease-in-out;
    .cloud {
      position: relative;
      margin: 21% auto 0;
      width: 65px;
      height: 40px;
      background: url('../../../images/svg/cloud.svg') no-repeat;
    }
    .tip {
      color: $linkBlue;
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
    }
    .ext {
      font-size: 14px;
      line-height: 19px;
      color: #405f90;
    }
    &.hide {
      display: none;
    }
  }
  .edit-block {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 19px;
    .icon {
      cursor: pointer;
      display: inline-block;
      vertical-align: top;
      margin-top: -50px;
      opacity: 0;
      color: $white;
      transition: opacity 0.5s ease, margin-top 0.35s 0.05s cubic-bezier(0.170, 0.550, 0.710, 1.650);
    }
  }
  .load-screen {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &.loading {
    border: 2px solid #2b3f63;
    .base {
      transform: translate(-50%, -50%) scale(0.0);
    }
    .load-screen {
      display: block;
    }
  }
  &.loaded {
    .edit-block {
      .icon {
        margin-top: 0;
        opacity: 0.99;
      }
    }
    .base {
      display: none;
    }
  }
  &.hover {
    border: 2px dashed $linkBlue;
    .base {
      display: block;
    }
  }

}

.system-message {
  display: inline-block;
  vertical-align: top;
  padding: 7px 14px 7px 12px;
  margin-bottom: 10px;
  color: $white;
  font-size: 14px;
  line-height: 1.5em;
  cursor: default;
  border-radius: 2px;
  &:empty{
    padding: 0;
    margin: 0;
  }
  &.error {
    border-left: 4px solid $red;
    background-color: #8f3d50;
    &.smooth-error {
      animation: fadeIn 3s;
      animation-fill-mode: forwards;
    }
  }
  &.system {
    border-left: 4px solid #53709B;
    background-color: rgba(68, 83, 126, 0.5);
  }
  &.system-message_yellow {
    margin-top: 16px;
    
    background: rgba(231, 188, 41, 0.24);
    border-left: 4px solid $chatYellow;
  }
  &.success {
    border-left: 4px solid $softGreen;
    background-color: #41765f;
  }
  &.warning {
    border-left: 4px solid #e67919;
    background-color: #b1611b;
  }
  &.smooth {
    animation: fadeIn 3s;
    animation-fill-mode: forwards;
  }
}

.btn.disabled, .btn[disabled], fieldset[disabled] {
  pointer-events: none;
  cursor: not-allowed;
  box-shadow: none;
}

.btn {
  position: relative;
  display: inline-block;
  width: auto;
  vertical-align: top;
  color: $white;
  font-size: 14px;
  line-height: 19px;
  border: none;
  padding: 8px 16px;
  outline: none !important;
  cursor: pointer;
  border-radius: 2px;
  user-select: none;
  text-decoration: none;
  &:hover {
    color: $white;
  }
  &.btn-blue {
    background-color: #3a6097;
    &:hover {
      background-color: #4d73ac;
    }
    &:active {
      background-color: #3864a4;
    }
    &.light {
      background-color: #5d94e2;
      &:hover {
        background-color: #7fa5dd;
      }
      &:active {
        background-color: #4c8dea;
      }
    }
  }
  &.btn-transparent {
    color: $linkBlue;
    background-color: #2b3553;
    &:hover {
      color: $linkBlueHover;
      background-color: #353f5d;
    }
    &:active {
      color: $white;
      background-color: #3f4967;
    }
    &.two{
      background-color: #303d61;
      &:hover{
        background-color: #384467;
      }
    }
    &.dark {
      background-color: #181b24;
      &:hover {
        background-color: #272c3b;
      }
      &:active {
        background-color: #2f3547;
      }
    }
  }
  &.btn-red {
    background-color: #b83939;
    &:hover {
      background-color: #d15d5d;
    }
    &:active {
      background-color: #c73737;
    }
    &.light {
      background-color: $red;
      &:hover {
        background-color: $salmon;
      }
      &:active {
        background-color: #ff3c3c;
      }
    }
  }
  &.btn-gray {
    background-color: #607695;
    &:hover {
      background-color: #7b8da5;
    }
    &:active {
      background-color: #5b79a4;
    }
    &.light {
      background-color: #8a9db7;
      &:hover {
        background-color: #93a9c4;
      }
      &:active {
        background-color: #8a9db7;
      }
    }
  }
  &.btn-green {
    background-color: #3a9749;
    &:hover {
      background-color: #52ab60;
    }
    &:active {
      background-color: #35a847;
    }
    &.light {
      background-color: #3faf56;
      &:hover {
        background-color: #5cb16d;
      }
      &:active {
        background-color: #3ac156;
      }
    }
  }
  &.btn-twitter {
    color: $white;
    background-color: #5d9ec9;
    &:before {
      content: '\e020';
      font-family: 'icomoon';
      font-size: 25px;
      display: inline-block;
      vertical-align: top;
      margin-right: 13px;
    }
  }
  &.btn-vk {
    color: $white;
    background-color: #4b6c91;
    &:hover {
      background-color: #5e83ad;
    }
    &:before {
      content: '\e018';
      font-family: 'icomoon';
      font-size: 25px;
      display: inline-block;
      vertical-align: top;
      margin-right: 13px;
    }
  }
  &.btn-fb {
    color: $white;
    background-color: #3a599a;
    padding: 7.5px 16px;
    &:before {
      content: '\e021';
      font-family: 'icomoon';
      font-size: 30px;
      display: inline-block;
      vertical-align: top;
      margin: 4px 3px -3px -8px;
    }
  }
  &.btn-ok {
    color: $white;
    border: 1px solid #eb722e;
    background-color: #eb722e;
    &:before {
      content: '\e925';
      font-family: 'icomoon';
      font-size: 21px;
      display: inline-block;
      vertical-align: top;
      margin-right: 13px;
    }
  }
  &.btn-pending {
    border: solid 2px #3a9749;
    padding: 6px 16px;
  }
  &.btn-plus{
    background-color: #a74ac6;
    &:hover {
      background-color: #E04BFF;
    }
    &:active {
      background-color: #BF4CDE;
    }
  }
  &.disabled {
    cursor: default;
    pointer-events: none;
  }

  &.btn-blue, &.btn-gray, &.btn-green, &.btn-red {
    &.disabled {
      background-color: #656871;
      color: #b2b3b8;
      &.light {
        background-color: #8f929b;
        color: #c7c8cd;
      }
    }
  }

  &.loading {
    color: transparent;
    width: inherit;
    height: inherit;
    min-width: 54px;
    background-image: url(/images/chat/ajax_loading.svg);
    background-position: center;
    background-repeat: no-repeat;
  }

  &.wide{
    display: block;
    text-align: center;
  }
  &.btn-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      font-size: 12px;
      margin-right: 8px;
    }
  }
}

input[type="text"], textarea, input[type="tel"], input[type="password"], input[type="email"], input[type="number"], .textarea, input[type="url"] {
  display: block;
  color: $white;
  width: 100%;
  font-size: 14px;
  line-height: 19px;
  padding: 7px 11px;
  border: 1px solid transparent;
  background-color: $black;
  border-radius: 2px;
  &::placeholder {
    color: #565a64;
  }
  &:focus, &.focused {
    border: 1px solid $accent;
    outline: none;
    box-shadow: inset 0 0 6px 0 rgba(82, 112, 156, 0.3);
  }
  &:invalid, &.error, &.ng-invalid.ng-touched {
    border: 1px solid $red;
    box-shadow: inset 0 0 6px 0 rgba(254, 110, 110, 0.3);
  }
  &.disabled,&:disabled{
    cursor: default;
    pointer-events: none;
    color: $gray;
    border-color: $gray;
    background-color: transparent;
    box-shadow: inset 0 0 0 1px $gray;
  }
}

.checkbox, .radio {
  position: relative;
  font-size: 14px;
  line-height: 19px;
  color: $white;
  label {
    padding-left: 25px;
    user-select: none;
    cursor: pointer;
  }
  &.disabled {
    opacity: 0.5;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
  }
}

.checkbox {
  label {
    min-width: 20px;
    line-height: 19px;
  }
}


input[type="checkbox"],
input[type="radio"] {
  display: none;
}

// TMP FORMS FIX:
bdo input[type="checkbox"] {
  display: inline;
}

input[type="checkbox"] + label::before,
input[type="radio"] + label::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 0;
  display: inline-block;
  height: 15px;
  width: 15px;
  margin: 0 5px 0 0;
  background: url(/images/rc-sprite2.png) no-repeat;
}

input[type="checkbox"] + label::before {
  background-position: -5px -5px;
}

input[type="checkbox"] + label:hover::before {
  background-position: -5px -30px;
}


input[type="checkbox"]:checked + label::before {
  background-position: -5px -55px;
}

input[type="radio"] + label::before {
  background-position: -30px -5px;
}

input[type="radio"] + label:hover::before {
  background-position: -30px -30px;
}

input[type="radio"]:checked + label::before {
  background-position: -30px -55px;
}

.radio.active{
  input[type="radio"] + label::before {
    background-position: -30px -55px;
  }
}

.dropdown {
  position: relative;
  color: $white;
  font-size: 14px;

  select, .dropdown-btn {
    min-width: 200px;
    padding: 6px 11px;
    cursor: pointer;
    border: 1px solid transparent;
    background-color: $black;
    height: 35px;
    border-radius: 2px;
  }

  &.disabled {
    opacity: 0.7;
  }
  user-select: none;
  .hidden {
    display: none;
  }
  .dropdown-btn {
    &:hover {
      border: 1px solid $accent;
      box-shadow: inset 0 0 6px 0 rgba(81, 112, 156, 0.3);
    }
    .text {
      margin-right: 20px;
      line-height: 21px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .caret {
      position: absolute;
      top: 16px;
      right: 12px;
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 2px;
      vertical-align: middle;
      border-top: 4px solid;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }
    &.disabled {
      color: #565a64;
      cursor: default;
      &:hover {
        border: 1px solid transparent;
        box-shadow: none;
      }
    }
  }
  .dropdown-menu {
    position: absolute;
    top: 0;
    display: none;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    min-width: 160px;
    width: 100%;
    background-color: $semi;
    z-index: 10;
    border-radius: 2px;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.3);
    &.divider {
      height: 1px;
      margin: 9px 0;
      overflow: hidden;
      background-color: #e5e5e5;
    }
    > li, .simplebar-content > li {
      > a {
        display: block;
        padding: 7px 12px;
        line-height: 21px;
        font-weight: normal;
        color: $white;
        text-decoration: none;
        white-space: nowrap;
        &:hover, &:focus {
          text-decoration: none;
          background-color: $accent;
        }
      }
      hr {
        height: 1px;
      }
    }
    &.active {
      > a, a:hover, a:focus {
        color: $white;
        text-decoration: none;
        outline: 0;
        background-color: #337ab7;
      }
    }
    .disabled {
      > a {
        color: #777777;
        &:hover, &:focus {
          color: #777777;
          text-decoration: none;
          background-color: transparent;
          background-image: none;
          cursor: not-allowed;
        }
      }
    }
  }
  &.open {
    z-index: 100;
    .dropdown-menu {
      display: block;
    }
    > a {
      outline: 0;
    }
  }
  .dropdown-header {
    display: block;
    padding: 3px 20px;
    font-size: 12px;
    line-height: 1.42857143;
    color: #777777;
    white-space: nowrap;
  }
  &.blue {
    .dropdown-btn {
      background-color: #3a6097;
      &:hover {
        background-color: #4d73ac;
        border: 1px solid #6f93ca;
      }
      &.disabled {
        color: #ccd9eb;
        background-color: #5a7398;
        > a {
          &:hover, &:focus {
            text-decoration: none;
            background-color: transparent;
            cursor: not-allowed;
          }
        }
        &:hover {
          background-color: #5a7398;
          border: 1px solid transparent;
        }
      }
    }
    .dropdown-menu {
      background-color: #3a6097;
      > li {
        > a {
          &:hover {
            background-color: #617fab;
          }
        }
      }
    }
  }
}

.dropdown-toggle:focus {
  outline: 0;
}

:not(table.gg-spoiler) {
  .spoiler-block {
    .spoiler-head {
      user-select: none;
    }
  }
}

gg-custom-spoiler .spoiler-block {
  display: block;
}

gg-custom-spoiler > * {
  display: none;
}

.spoiler-block {
  position: relative;
  // padding: 14px 0;
  .spoiler-head {
    position: relative;
    padding: 8px 85px 8px 16px;
    font-size: 14px;
    line-height: normal;
    min-height: 35px;
    color: $veryLightBlue;
    background-color: rgba(79, 89, 119, 0.3);
    cursor: pointer;
    border-radius: 2px;
    &:after {
      content: attr(data-show);
      position: absolute;
      top: 8px;
      right: 16px;
      color: $linkBlueHover;
    }
    &:hover {
      background-color: rgba(79, 89, 119, 0.5);
      &:after {
        color: $linkBlue;
      }
      .show-link {
        color: #4f98ff;
      }
    }
    .show-link {
      position: absolute;
      top: 8px;
      right: 16px;
      .show {
        display: block;
      }
      .hidden {
        display: none;
      }
    }
  }
  .spoiler-content {
    border: 2px solid transparent;
    border-top: none;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease-in-out;
    .inner-block {
      padding: 30px;
    }
  }
  &.active {
    .spoiler-head {
      border-radius: 2px 2px 0 0;
      &:after {
        content: attr(data-hide);
      }
    }
    .spoiler-content {
      padding: 18px 24px;
      height: auto;
      border-color: rgba(79, 89, 119, 0.3);
    }
    .show-link {
      .show {
        display: none;
      }
      .hidden {
        display: block;
      }
    }
  }
}

.counter-block {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  span {
    vertical-align: middle;
    display: inline-block;
    margin: 0 4px;
    color: $semi;
    &.icon {
      cursor: pointer;
      &:hover {
        color: $accent;
      }
    }
    &.icon-minus.active {
      color: $normalText;
    }
    &.icon-plus.active {
      color: $green;
    }
    &.counter {
      overflow: hidden;
      height: 14px;
      .counter-all {
        font-size: 16px;
        margin-top: -14px;
        transform: translateZ(0);
        transition: all 0.5s ease;
        span {
          display: block;
          line-height: 14px;
          &.minus {
            color: $normalText;
          }
          &.plus {
            color: $green;
          }
        }
        &.minus {
          margin-top: 0;
        }
        &.plus {
          margin-top: -41px;
        }
      }
    }
  }
  &.small {
    font-size: 12px;
    span {
      vertical-align: middle;
      display: inline-block;
      margin: 0 3px;
      &.counter {
        overflow: hidden;
        height: 14px;
        .counter-all {
          font-size: 14px;
          &.minus {
            margin-top: 0;
          }
          &.plus {
            margin-top: -40px;
          }
        }
      }
    }
  }
}

.tab-panel {
  .nav-tabs {
    list-style: none;
    padding: 0;
    margin: 0 0 15px 0;
    font-size: 16px;
    line-height: normal;
    background-color: $black;
    *zoom: 1;
    &:before,
    &:after {
      display: table;
      content: "";

      line-height: 0;
    }
    &:after {
      clear: both;
    }
    .desktop{
      display: block;
    }
    li {
      float: left;
      a {
        display: block;
        padding: 12px 16px;
        &:hover {
          color: $white;
          background-color: $accent;
        }
      }
      &.active {
        a {
          color: $white;
          background-color: $mainColor;
        }
      }
    }
    &.nav-justified {
      width: 100%;
      display: flex;
      li {
        flex: 1;
        a {
          text-align: center;
        }
      }
    }
  }
  .tab-content {
    font-size: 14px;
    padding: 0 16px;
    .tab-pane {
      display: none;
      visibility: hidden;
      &.active {
        display: block;
        visibility: visible;
      }
    }
  }
}

.gg-calendar {
  position: relative;
  input {
    padding: 7px 33px 7px 11px;
  }
  .icon-calendar {
    position: absolute;
    top: 9px;
    right: 12px;
    font-size: 17px;
    cursor: pointer;
  }
  .calendar-block {
    position: absolute;
    z-index: 10;
    width: 244px;
  }
}

.calendar-block {
  color: $white;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.3), 0 6px 6px 0 rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  background-color: $mainBackgr;
  .flex{
    display: flex;
    flex-flow: row nowrap;
    .element{
      flex-shrink: 0;
      height: 246px;
      &.calendar{
        width: 238px;
      }
      &.featured{
        width: 265px;
      }
    }
  }
  .tse-scrollbar{
    .drag-handle{
      width: 6px;
      border-radius: 3px;
      background-color: #6e7895;
    }
  }

  .control-block {
    padding: 20px 12px 0;
    font-weight: 600;
    text-align: center;
    margin-bottom: 5px;
    cursor: default;
    .arrow {
      position: relative;
      padding: 5px 7px 5px 8px;
      cursor: pointer;
      &.left {
        float: left;
      }
      &.right {
        float: right;
      }
    }
  }
  .months-wrap {
    padding: 0 10px;
    .months {
      th, td {
        text-align: center;
        width: 32px;
        height: 32px;
      }
      th {
        cursor: default;
        font-weight: 600;
      }
      td {
        cursor: pointer;
        border-radius: 2px;
        &.other-month {
          opacity: 0.3; // Это чтобы сереньким показывались другие месяца
        }
        &:hover, &.active {
          background-color: $accent;
        }
      }
    }
  }
  .time-picker-block {
    position: relative;
    margin-top: 18px;
    padding: 6px 40px;
    background-color: #18203a;
    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -13px -1px;
      content: ":";
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
    }
    .time-picker {
      text-align: center;
      .arrow {
        padding: 6px;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
      }
      input[type="text"],
      input[type="number"] {
        margin: 7px 0;
        padding: 5px 10px;
        width: 42px;
        height: 40px;
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
      }

      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
      }

    }
  }
}

// надо будет удалить как только новый datepicker сделают

.ui-progress {
  canvas {
    position: absolute;
  }
}

// Перенес из _streams-page.scss
.ui-switch {
  position: relative;
  background-color: $black;
  margin: 0 24px 0 13px;
  border: none;
  box-shadow: none;
  padding: 10px 0;
  border-radius: 40px;
  color: $white;
  a {
    color: $white;
    padding: 10px 16px;
    position: relative;
    z-index: 1;
    margin: 0 !important;
  }
  .active {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: auto;
    height: 100%;
    border-radius: 40px;
    background-color: #53709B;
  }
}

.popup-block-new {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  background-color: rgba(0,0,0,.7);
  z-index: 10;
  .popup-inner {
    position: absolute;
    top: 60px;
    left: 50%;
    width: 100%;
    max-width: 768px;
    min-width: 320px;
    margin-bottom: 60px;
    transform: translate(-50%,0);
  }
  .icon-close3 {
    position: absolute;
    top: 24px;
    right: 24px;
    color: $white;
    font-size: 18px;
    cursor: pointer;
    &:hover {
      color: $linkBlue;
    }
  }

  @media (max-width: 768px) {
    .popup-inner {
      top: 0;
    }
  }
}

.task-modal {
  padding: 24px;
  color: $white;
  background-color: $black;

  .mobile-switch {
    max-width: 245px;
  }
}

.mobile-switch {
  width: 100%;
  display: flex;
  background-color: $black;
  padding: 2px;
  border-radius: 100px;
  a {
    padding: 4px 16px;
    width: 50%;
    text-align: center;
    white-space: nowrap;
    &.active {
      color: $white;
      background-color: $accent;
      border-radius: 20px;
      &.inner{
        background-color: transparent;
        box-shadow: inset 0 0 0 2px $accent;
      }
    }
  }
}

.flex {
  display: flex;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-stretch {
  align-items: stretch;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}
