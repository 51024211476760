/*
  Блок логина
*/
@mixin current-step {
  opacity: 1;
  z-index: 1;
  transition: opacity .2s ease-in-out;
  transition-delay: .35s;
}

.login-wrap-big {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(13, 18, 33, 0.6);
  z-index: 10;
}
.login-wrap-block {
  position: absolute;
  top: 10vh;
  left: 50%;
  width: 360px;
  transform: translate(-50%, -50%);
  z-index: 10;

  .inner-wrap {
    position: absolute;
  }
  .icon-close2 {
    position: absolute;
    top: 0;
    right: -45px;
    font-size: 20px;
    color: $veryLightBlue;
    cursor: pointer;
  }
  .step-block {
    position: absolute;
    top: 46%;
    width: 360px;
    overflow: hidden;
    background-color: $mainColor;
    transition: height .15s ease-in-out;
    transition-delay: .2s;
    .system-message{
      margin-bottom: 3.1%;
    }
    .step {
      position: absolute;
      padding: 13.89%;
      width: 100%;
      border-radius: 2px;
      opacity: 0;
      transition: opacity .2s ease-in-out;
      .btn {
        width: 100%;
      }
      .text {
        margin-bottom: 6.923%;
      }
      .login-block {
        margin-bottom: 9.231%;
      }
      .checkbox {
        margin-bottom: 9.23%;
      }
      &:first-child {
        padding-bottom: 8.05%;
      }

      &.link-social, &.remember-step {
        .flex {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
        }
        .title {
          margin-bottom: 4.615%;
        }
        .btn {
          width: 45%;
          text-align: center;
          padding-left:0;
          padding-right: 0;
        }
      }
    }

    &.opened{
      .step{
        transition: none !important;
      }
    }

    &.one {
      height: 570px;
      .step:first-child {
        @include current-step;
      }
    }

    &.login-step-twitch {
      height: 530px;
      &.warn-email{
        height: 586px;
      }
      &.warn-auth{
        height: 607px;
      }
      &.warn-email.warn-auth{
        height: 665px;
      }
      &.second{
        height: 358px;
        &.warn-nick{
          height: 445px;
        }
      }

      .login-step-twitch {
        @include current-step;
      }
    }
    &.login-step {
      height: 525px;
      .login-step {
        @include current-step;
      }
    }
    &.registration-step {
      height: 740px;
      .registration-step {
        @include current-step;
      }
    }
    &.link-social {
      height: 467px;
      .link-social {
        @include current-step;
      }
    }
    &.registration-final {
      height: 338px;
      .registration-final {
        @include current-step;
      }
    }
    &.very-final {
      height: 424px;
      .very-final {
        @include current-step;
      }
    }
    &.remember-step {
      height: 360px;
      .remember-step {
        @include current-step;
      }
    }
    &.remember-finish {
      height: 248px;
      .remember-finish {
        @include current-step;
      }
    }
    &.remember-ok {
      height: 267px;
      .remember-ok {
        @include current-step;
      }
    }
    &.remember-error {
      height: 267px;
      .remember-error {
        @include current-step;
      }
    }
  }
  .login-hint-block {
    color: $white;
    margin: 0 0 6.667%;
    .logo-block {
      margin-bottom: 17.692%;
    }
    img {
      display: block;
      margin: 0 auto;
    }
    svg {
      width: 100%;
      height: 61px;
    }
  }
  .login-list {
    width: 100%;
    margin: 0 auto 9.231%;
    .btn {
      display: block;
      margin: 0 auto 4.615%;
      width: 100%;
      font-weight: 600;
      padding: 3% 2.308% 3% 3.1%;
      line-height: 23px;
      border-radius: 2px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
      &:before {
        content: '';
        display: inline-block;
        vertical-align: top;
        width: 24px;
        height: 24px;
        margin-right: 7.6%;
      }
      &.btn-vk2 {
        background-color: #4c6c91;
        &:before {
          background: url('/images/svg/social/vk-24-px.svg');
        }
        &:hover {
          background-color: lighten(#4c6c91, 10%);
        }
      }
      &.btn-twitch{
        background-color: #6441a4;
        &:before{
          background: url('/images/svg/social/twitch-24-px.svg');
        }
        &:hover {
          background-color: lighten(#6441a4, 10%);
        }
      }
      &.btn-fb2 {
        background-color: #3a599a;
        &:before {
          background: url('/images/svg/social/fb-24-px.svg');
        }
        &:hover {
          background-color: lighten(#3a599a, 10%);
        }
      }
      &.btn-twitter {
        background-color: #55acee;
        &:before {
          background: url('/images/svg/social/twitter-24-px.svg');
        }
        &:hover {
          background-color: lighten(#55acee, 10%);
        }
      }
      &.btn-google {
        color: rgba(0, 0, 0, 0.54);
        background-color: $white;
        &:before {
          background: url('/images/svg/social/google-24-px.svg');
        }
        &:hover {
          color: rgba(0, 0, 0, 0.34);
        }
      }
      &.btn-bnet {
        background-color: #153d5f;
        &:before {
          background: url('/images/svg/social/bnet-24-px.svg');
        }
        &:hover {
          background-color: lighten(#153d5f, 10%);
        }
      }
      &.btn-od {
        background-color: #E7792B;
        &::before {
          background: url('/images/svg/social/od-24-px.svg');
        }
        &:hover {
          filter: brightness(1.2);
        }
      }
    }
  }
  .link-list {
    text-align: center;
    .link-block {
      margin-bottom: 18px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .form-group {
    margin-bottom: 1.7143em;
    label {
      position: relative;
      display: block;
      color: $white;
      font-weight: 600;
      margin-bottom: 3.1%;
      &:hover {
        .tip {
          display: block;
          width: 260px;
          white-space: normal;
          left: 39%;
          top: -50px;
        }
      }
    }
  }
  .login-block {
    .btn-block {
      margin: 32px 0 41px;
      .btn {
        text-align: center;
        width: 90px;
        margin-right: 14px;
      }
    }
    .captcha-block {
      margin: 13px 0 -20px 0;
    }
  }
  .title {
    margin-bottom: 1em;
    white-space: nowrap;
    font-size: 1.7143em;
    font-weight: 600;
    color: $white;
  }
  .captcha-block {
    margin: -3px 0 18px 2px;
    transform: scale(0.8515);
    transform-origin: 0 0;
  }
  .registration-block {
    width: 100%;
    margin: 0 auto 9.231%;
    .btn {
      text-align: center;
      width: 100%;
    }
  }
  .remember-finish, .remember-ok, .remember-error {
    .text {
      text-align: center
    }
    .btn {
      text-align: center;
      width: 80px;
      margin: 0 auto;
    }
  }
  .last-step-block {
    color: $white;
    .text {
      margin-bottom: 0.77778em;
    }
    .btn {
      width: 100%;
      text-align: center;
    }
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 3),
  only screen and (min-resolution: 3dppx),
  only screen and (min-resolution: 350dpi){
    .inner-wrap{
      .step-block{
        .step{
          padding: 24vh 30vw;
          transform: scale(1.8);
        }
      }
    }
  }

  @media (max-width: 767px) {
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: none;
    .registration-block{
      .captcha-block{
        width: 304px;
        transform: unset;
        transform-origin: unset;
        margin-left:auto;
        margin-right: auto;
      }
    }
    .inner-wrap{
      width: 100%;
      height: 100%;
      .step-block{
        top: 0;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        .step {
          padding: 20px;
          transform: none;
          &.login-step{
            .login-hint-block{
              .title{
                margin-bottom: 0;
              }
            }
          }
          &.registration-step {
            .step {
              &.registration-step {
                overflow-y: scroll;
                padding-bottom: 70px;
                .btn.fixed {
                  position: fixed;
                  bottom: 0;
                  left: 0;
                  border: solid $mainColor;
                  border-width: 0 20px 20px 20px;
                }
              }
            }
          }
        }
      }
    }
    .icon-close2{
      top: 15px;
      right: 15px;
      z-index: 2;
    }
  }

  @media only screen and (max-device-width: 360px) {
    .registration-block {
      .captcha-block {
        width: 195px;
        transform: scale(0.8);
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}